import { $dayjs } from '@utils/dayjs';
import { IUnitsReport, MigrationState } from '@hooks/migration-report/use-migration-report-by-group/types';
import { triggerAmplitudeButtonClickedEvent } from '@services/event-tracking/events-trigger';

export const getConcludedMigrationUnits = (migrationReportByUnits: IUnitsReport[]) => {
  const filteredUnitsByMigrationProgressValue =
    migrationReportByUnits && migrationReportByUnits.filter((item) => item.conclusionPercentage === 1);

  return filteredUnitsByMigrationProgressValue?.length > 0 ? filteredUnitsByMigrationProgressValue : null;
};

export const triggerButtonEvent = (buttonText: string, destinationPath?: string) => {
  return triggerAmplitudeButtonClickedEvent('Migração', buttonText, destinationPath);
};

export const isOverdue = (dueDate: string | null) => {
  if (!dueDate) {
    return false;
  }
  const dueDateObj = $dayjs(dueDate);
  const today = $dayjs();
  return today.isAfter(dueDateObj, 'day');
};

export const getMigrationState = (progress: number, dueDate: string | null): MigrationState => {
  if (progress === 1) {
    return MigrationState.DONE;
  }
  return isOverdue(dueDate) ? MigrationState.OVERDUE : MigrationState.IN_PROGRESS;
};
