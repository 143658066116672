import { useLazyQuery, useMutation } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useState } from 'react';
import { createContext } from 'use-context-selector';

import { GroupsUsersManager, UsersManager } from './manager';
import { UPSERT_GROUP_RELATION_WITH_USER } from './mutation';
import { GET_USERS_QUERY, QUERY_GET_GROUPS_BY_USER, QUERY_GET_GROUP_BY_USER } from './queries';
import { Group, IUserContext, UserInfo } from './types';

export const UserInfoContext = createContext({} as IUserContext);

export const UserInfoProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isAuthenticated, user, isLoading } = useAuth0();

  const [userInfo, setUserInfo] = React.useState<UserInfo>({
    id: '',
    name: 'not loaded',
    email: '',
    trader: null,
    isAdmin: false,
    group: {
      id: '',
      name: '',
      // @ts-expect-error - is just to empty value
      type: null,
      units: [],
    },
  });
  const [isUserLoaded, setIsUserLoaded] = React.useState<boolean>(false);
  const [isUpsertRelationSuccess, setIsUpsertRelationSuccess] = useState<boolean>(false);
  const [isLoadingUpsertMutation, setIsLoadingUpsertMutation] = useState<boolean>(false);
  const [groupsByUser, setGroupsByUser] = useState<Group[]>([]);

  const [GetGroupByUser] = useLazyQuery(QUERY_GET_GROUP_BY_USER, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const userGroupId = data?.getGroupByUser.group.id;
      const userGroupName = data?.getGroupByUser.group.name;
      const userGroupType = data?.getGroupByUser.group.type;
      if (user) {
        setUserInfo((previous) => ({
          ...previous,
          group: { id: userGroupId, name: userGroupName, units: data.getGroupByUser.group.units, type: userGroupType },
        }));

        setIsUserLoaded(true);
      }
    },
    onError: (e) => {
      setIsUserLoaded(false);
      throw `ERROR: ${e}`;
    },
  });

  const [GetGroupsByUser] = useLazyQuery(QUERY_GET_GROUPS_BY_USER, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const manager = new GroupsUsersManager(data);
      setGroupsByUser(manager.groupsByUser.map((item) => item.group));
      let userGroup = userInfo.group;
      if (!userGroup?.id && manager.groupsByUser.length) {
        userGroup = manager.groupsByUser[0].group;
      }
      setUserInfo((previous) => ({
        ...previous,
        groups: manager.groupsByUser.map((item) => item.group),
        group: userGroup,
      }));
    },
    onError: (e) => {
      setIsUserLoaded(false);
      throw `ERROR: ${e}`;
    },
  });

  const [upsertGroupRelationWithUser, { loading: loadingUpsertGroupRelationWithUser }] = useMutation(
    UPSERT_GROUP_RELATION_WITH_USER,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setIsUpsertRelationSuccess(data.upsertGroupsUser);
      },
      onError: (e) => {
        throw `ERROR: ${e}`;
      },
    },
  );

  function getGroupByUserHandler(userId: string) {
    setIsUserLoaded(false);
    const variables = {
      id: userId,
    };

    GetGroupsByUser({ variables });
    return GetGroupByUser({ variables });
  }

  const upsertGroupRelationWithUserHandler = async (groupId: string) => {
    setIsLoadingUpsertMutation(true);
    return upsertGroupRelationWithUser({
      variables: {
        input: {
          userId: user && user['https://clarke.com.br/uuid'],
          groupId,
        },
      },
    });
  };

  const isUserAdmin = () => user && [...user['https://clarke.com.br/roles']]?.find((role) => role === 'admin');
  React.useEffect(() => {
    if (isAuthenticated && user) {
      setUserInfo((previous) => ({
        ...previous,
        id: user['https://clarke.com.br/uuid'],
        name: user.name as string,
        email: user.email as string,
        isAdmin: isUserAdmin(),
        trader: null,
        photoUrl: user.picture,
      }));
      getGroupByUserHandler(user['https://clarke.com.br/uuid']);
    }
  }, [isAuthenticated]);

  return (
    <UserInfoContext.Provider
      value={{
        user: userInfo,
        isFetchingUser: isLoading || loadingUpsertGroupRelationWithUser || isLoadingUpsertMutation,
        isUserLoaded,
        upsertGroupRelationWithUserHandler,
        getUser: () => getGroupByUserHandler(user && user['https://clarke.com.br/uuid']),
        isUpsertRelationSuccess,
        groupsByUser,
        setUserGroup: (groupId: string) => {
          const group = groupsByUser.find((group) => group.id === groupId);
          if (!group) return;
          setUserInfo((previous) => ({
            ...previous,
            group,
          }));
        },
      }}
    >
      {children}
    </UserInfoContext.Provider>
  );
};

export const UsersContext = createContext({} as UsersContextType);
export type UsersContextType = {
  getUsers: () => void;
  users: UserInfo[];
  loading: boolean;
};

export const UsersProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [users, setUsers] = React.useState<Array<UserInfo>>([]);
  const [getUsers, { called: calledGetUsers, loading }] = useLazyQuery(GET_USERS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const manager = new UsersManager(data);
      setUsers(manager.users);
    },
    onError: () => {
      setUsers([]);
    },
    variables: {},
  });

  if (!calledGetUsers) getUsers();
  return (
    <UsersContext.Provider
      value={{
        users,
        getUsers,
        loading,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};
