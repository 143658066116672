export const getRatio = (initial: number, final: number) => {
  if (initial == 0) {
    return 0;
  }
  if (initial >= final) {
    return getDecreaseRatio(initial, final);
  }
  return getIncreaseRatio(initial, final);
};

export const getIncreaseRatio = (initial: number, final: number) => {
  return (final - initial) / initial;
};
export const getDecreaseRatio = (initial: number, final: number) => {
  return final / initial;
};
