import { ButtonInlineProps, MultiSelectProps, OverflownMenuProps } from '@clarke-energia/foton';
import { CommercialGroup } from '@contexts/group-economy/types';

export interface UsersListGraphQLData {
  allUsers: UserInfo[];
}

export interface Group extends CommercialGroup {
  type: GroupTypeEnum;
  units: { id: string }[];
}

export enum GroupTypeEnum {
  RETAIL = 'RETAIL',
  MANAGEMENT_RETAIL = 'uANAGEMENT_RETAIL',
  MANAGEMENT_WHOLESALE = 'MANAGEMENT_WHOLESALE',
  LEAD = 'LEAD',
  RETAIL_TRANSFER_CONTRACTS = 'RETAIL_TRANSFER_CONTRACTS',
}

export interface UserInfo {
  id: string;
  name: string;
  email: string;
  trader: ITrader | null;
  isAdmin: boolean;
  createdAt?: string;
  photoUrl?: string;
  phone?: string;
  group?: Group;
  groups?: CommercialGroup[] | Group[];
}
export interface IUserContext {
  user: UserInfo;
  isFetchingUser: boolean;
  isUserLoaded: boolean;
  getUser: () => void;
  upsertGroupRelationWithUserHandler(groupId: string): void;
  isUpsertRelationSuccess: boolean;
  groupsByUser: CommercialGroup[];
  setUserGroup(groupId: string): void;
}

export interface ITrader {
  id: string;
  name: string;
}

export interface UserInfoGraphQLData {
  me: {
    id: string;
    name: string;
    email: string;
    trader: {
      id: string;
      name: string;
    };
    phone: string;
    createdAt: string;
  };
}

export enum IApplications {
  vendas = 'Área do Vendedor',
  marketplace = 'Marketplace',
  migracao = 'Relatório de Migração',
  economia = 'Relatório de Economia',
  parceiros = 'Parceiros',
  admin = 'Clarke Admin',
  cliente = 'Área do Cliente',
}

export interface DropdownMenuProps extends React.ComponentProps<'div'> {
  dropdownMenuHeader?: DropdownMenuHeaderProps;
  dropdownMenuBody?: DropdownMenuBodyProps;
  dropdownMenuFooter?: OverflownMenuProps;
  onClose?: () => void;
}

export interface DropdownMenuHeaderProps {
  title: string;
  subtitle: string;
  avatarUrl?: string;
}

export interface DropdownMenuBodyProps {
  title: string;
  mainSectionProps: MainSectionProps;
  secondarySectionProps: SecondarySectionProps;
}

interface MainSectionProps {
  overflownMenusProps?: OverflownMenuProps[];
  buttonProps?: ButtonInlineProps;
  hasUserAuthorization?: boolean;
}

interface SecondarySectionProps {
  multiSelectProps?: MultiSelectProps<any>;
}

export interface UpsertGroupsUsersInput {
  userId: string;
  groupId: string;
}

export interface IDecodedToken {
  scope: string;
}

export interface GroupsUsersGraphQLData {
  getGroupsByUser: GroupsByUser[];
}

export interface GroupsByUser {
  group: Group;
}
