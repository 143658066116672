import { SummaryEconomyReportsHistory } from '@contexts/group-economy/types';
import { formatMonthAndYearDate } from '@utils/date';

const getReportByMonthYear = (groupAccumulatedEconomyReports: SummaryEconomyReportsHistory[], monthYear: string) => {
  return groupAccumulatedEconomyReports.find((economyReport) => {
    return formatMonthAndYearDate(economyReport.date) === monthYear;
  });
};

export const calculateRelativePercent = (params: {
  monthYear: string;
  groupAccumulatedEconomyReports: SummaryEconomyReportsHistory[];
  value: number | null;
  getValue: (economyReport: SummaryEconomyReportsHistory) => number;
}) => {
  if (params.value === null) return null;

  const economyReport = getReportByMonthYear(params.groupAccumulatedEconomyReports, params.monthYear);
  if (!economyReport) return null;

  const previousReportIndex = params.groupAccumulatedEconomyReports.indexOf(economyReport) - 1;
  if (previousReportIndex < 0) return null;

  const previousReport = params.groupAccumulatedEconomyReports[previousReportIndex];
  const previousValue = params.getValue(previousReport);
  return ((params.value - previousValue) / previousValue) * 100;
};

export const getConsumptionsParamsByMonth = (
  groupAccumulatedEconomyReports: SummaryEconomyReportsHistory[],
  monthYear: string,
) => {
  const economyReport = getReportByMonthYear(groupAccumulatedEconomyReports, monthYear);

  return {
    economy: {
      value: economyReport?.monthlySavings || null,
      percent: calculateRelativePercent({
        monthYear,
        groupAccumulatedEconomyReports,
        value: economyReport?.monthlySavings || null,
        getValue: (economyReport) => economyReport.monthlySavings,
      }),
    },
    cost: {
      value: economyReport?.monthlyEnergyCost || null,
      percent: calculateRelativePercent({
        monthYear,
        groupAccumulatedEconomyReports,
        value: economyReport?.monthlyEnergyCost || null,
        getValue: (economyReport) => economyReport.monthlyEnergyCost,
      }),
    },
    consumption: {
      value: economyReport?.monthlyEnergyConsumption || null,
      percent: calculateRelativePercent({
        monthYear,
        groupAccumulatedEconomyReports,
        value: economyReport?.monthlyEnergyConsumption || null,
        getValue: (economyReport) => economyReport.monthlyEnergyConsumption,
      }),
    },
  };
};
