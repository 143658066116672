import React from 'react';

import { SelectIcon } from '@components/atoms/form/select-icon';

import style from './style.module.css';

export type ContractFilters = 'ENERGY' | 'MANAGEMENT' | 'ALL';

interface ContractOptions {
  label: string;
  value: ContractFilters;
}

interface TitleFilterProps {
  title: string;
  value: string;
  onChange: (value: string) => void;
}

export const TitleFilter: React.FC<TitleFilterProps> = ({ title, value, onChange }) => {
  const contractOptions: ContractOptions[] = [
    { label: 'Todos os contratos', value: 'ALL' },
    { label: 'Contratos de energia', value: 'ENERGY' },
    { label: 'Contratos de gestão', value: 'MANAGEMENT' },
  ];

  return (
    <div className={style.container}>
      <h2 className={style.contractSectionTitle}>{title}</h2>
      <div>
        <SelectIcon
          options={contractOptions}
          placeholder="Filtrar por tipo de contrato"
          onChange={onChange}
          value={value}
        />
      </div>
    </div>
  );
};

export default TitleFilter;
