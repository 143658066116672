import { useMemo } from 'react';

import { useLazyQuery } from '@apollo/client';

import { GetUnitDailyConsumptionByUnitIdAndMonthYearGraphQL } from './types';
import { GET_UNIT_DAILY_CONSUMPTION_PROJECTION_BY_UNIT_ID_AND_MONTH_YEAR } from './queries';

export const useGetUnitDailyConsumptionProjectionByUnitIdAndMonthYear = () => {
  const [getUnitDailyConsumptionProjectionByUnitIdAndMonthYear, { loading, data }] =
    useLazyQuery<GetUnitDailyConsumptionByUnitIdAndMonthYearGraphQL>(
      GET_UNIT_DAILY_CONSUMPTION_PROJECTION_BY_UNIT_ID_AND_MONTH_YEAR,
      {
        fetchPolicy: 'network-only',
      },
    );

  const getUnitDailyConsumptionProjectionByUnitIdAndMonthYearHandler = (unitId: string, monthYear: string) => {
    getUnitDailyConsumptionProjectionByUnitIdAndMonthYear({
      variables: {
        input: {
          unitId,
          monthYear,
        },
      },
    });
  };

  const dailyProjection = useMemo(() => {
    if (!data) {
      return {
        projections: [],
        monthYear: '',
        unitId: '',
      };
    }

    const projections = data.getMonthlyConsumptionProjectionByUnitId.dailyConsumptionProjection
      .sort((a, b) => {
        return a.date.localeCompare(b.date);
      })
      .map((el) => {
        el.consumptionPredicted =
          typeof el.consumptionPredicted == 'string' ? parseFloat(el.consumptionPredicted) : el.consumptionPredicted;
        return el;
      });

    const { monthYear, unitId } = data.getMonthlyConsumptionProjectionByUnitId;

    return {
      projections: projections,
      monthYear,
      unitId,
    };
  }, [data]);

  return {
    getUnitDailyConsumptionProjectionByUnitIdAndMonthYearHandler,
    dailyProjection,
    loading,
  };
};
