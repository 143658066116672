import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import { GET_MIGRATION_REPORT_BY_GROUP_ID } from './queries';

export const useGroupMigrationStatus = () => {
  const [groupHasMigrated, setGroupHasMigrated] = React.useState<boolean>();
  const [getMigrationReportByGroup, { data, loading, error }] = useLazyQuery(GET_MIGRATION_REPORT_BY_GROUP_ID, {
    fetchPolicy: 'network-only',
  });

  function getMigrationStatusByGroupHandler(groupId: string) {
    return getMigrationReportByGroup({ variables: { groupId } });
  }

  useEffect(() => {
    if (data) {
      setGroupHasMigrated(data.getMigrationReportByGroupId.conclusionPercentage === 1);
    }
  }, [data]);

  return {
    getGroupHasMigrated: getMigrationStatusByGroupHandler,
    groupHasMigrated,
    data: data && data.getMigrationReportByGroupId,
    loading,
    error,
  };
};
