import html2canvas from 'html2canvas';
import { DEFAULT_CLASS_HIDDEN_ON_EXPORT } from './constants';

interface ElementOpacity {
  element: HTMLElement;
  opacity: string;
}

const applyExtraClassNames = (element: HTMLElement, extraClassNames?: string) => {
  if (!extraClassNames) {
    return;
  }
  extraClassNames.split(' ').forEach((className) => {
    element.classList.add(className);
  });
};

const removeExtraClassNames = (element: HTMLElement, extraClassNames?: string) => {
  if (!extraClassNames) {
    return;
  }
  extraClassNames.split(' ').forEach((className) => {
    element.classList.remove(className);
  });
};

export const exportElementAsImage = async (params: {
  element: HTMLElement;
  exportName: string;
  classToHide?: string;
  extraClassNames?: string;
}) => {
  const elementsToHide = params.classToHide
    ? // Array.from is just to typescript
      (Array.from(params.element.querySelectorAll(`.${params.classToHide}`)) as HTMLElement[])
    : ([] as HTMLElement[]);
  elementsToHide.push(
    ...(Array.from(params.element.querySelectorAll(`.${DEFAULT_CLASS_HIDDEN_ON_EXPORT}`)) as HTMLElement[]),
  );

  applyExtraClassNames(params.element, params.extraClassNames);

  const originalOpacities: ElementOpacity[] = [];
  elementsToHide.forEach((el) => {
    originalOpacities.push({
      element: el,
      opacity: el.style.opacity,
    });
    el.style.opacity = '0';
  });

  const canvas = await html2canvas(params.element, {
    scale: 3,
    backgroundColor: '#f2f2f2',
    x: -20,
    y: -20,
    width: params.element.clientWidth + 40,
    height: params.element.clientHeight + 40,
  });
  const link = canvas.toDataURL('image/png');
  const linkElement = document.createElement('a');
  linkElement.href = link;
  linkElement.download = params.exportName + '.png';
  document.body.appendChild(linkElement);
  linkElement.click();

  document.body.removeChild(linkElement);

  removeExtraClassNames(params.element, params.extraClassNames);

  originalOpacities.forEach(({ element, opacity }) => {
    element.style.opacity = opacity;
  });
};

export const useExportElementAsImage = () => {
  return exportElementAsImage;
};
