import { gql } from '@apollo/client';

export const QUERY_GET_UNITS_ECONOMY_REPORTS_BY_GROUP_ID = gql`
  query GetUnitsEconomyReportsByGroupId($id: ID!) {
    unitsEconomyReportsByGroupId(id: $id) {
      id
      unit {
        id
        name

        edc {
          name
          state
        }
      }

      trader {
        name
      }

      energyTotalConsumption
      savingsPercent
      energyTotalCost
      savingBalancePercent
      energyCostBalancePercent
      energyConsumptionBalancePercent
      date
      currentSavings
      devecDeclaration
      carbonDioxideEmissionAvoided

      conventionalMarket {
        billedDemandOffPeakAmount
        billedDemandOffPeakCost
        billedDemandOffPeakTotal
        billedDemandPeakAmount
        billedDemandPeakCost
        billedDemandPeakTotal
        tusdPeakAmount
        tusdPeakCost
        tusdPeakTotal
        tusdOffPeakAmount
        tusdOffPeakCost
        tusdOffPeakTotal
        tePeakAmount
        tePeakCost
        tePeakTotal
        teOffPeakAmount
        teOffPeakCost
        teOffPeakTotal
        overtakingAmount
        overtakingCost
        overtakingTotal
        reactivesTotal
        tariffFlagCost
        tariffFlagTotal
        taxesTotal
        others
        totalValueWithoutTax
        totalValue
      }

      freeMarket {
        totalValue
        totalValueWithoutTax
        readDemandOffPeak
        energyDistribution {
          acrBilledDemandOffPeakAmount
          acrBilledDemandOffPeakCost
          acrBilledDemandOffPeakTotal
          acrBilledDemandPeakAmount
          acrBilledDemandPeakCost
          acrBilledDemandPeakTotal
          acrTeOffPeakAmount
          acrTeOffPeakCost
          acrTeOffPeakTotal
          acrTePeakAmount
          acrTePeakCost
          acrTePeakTotal
          billedDemandOffPeakAmount
          billedDemandOffPeakCost
          billedDemandOffPeakTotal
          billedDemandPeakAmount
          billedDemandPeakCost
          billedDemandPeakTotal
          tusdPeakAmount
          tusdPeakCost
          tusdPeakTotal
          tusdOffPeakAmount
          tusdOffPeakCost
          tusdOffPeakTotal
          covidTariffAmount
          covidTariffCost
          covidTariffTotal
          overtakingAmount
          overtakingCost
          overtakingTotal
          reactivesTotal
          taxesTotal
          others
          totalValue
        }
        energyProvided {
          energyProvidedAmount
          energyProvidedCost
          energyProvidedTotal
          energyProvidedAdjustmentValue
          highlightPisCofinsCost
          highlightPisCofinsTotal
          icmsCost
          icmsTotal
          totalValue
        }
        others {
          clarkeManagementCost
          totalValue
          cceeCost
          eerCost
          essCost
        }
      }
      status
    }
  }
`;

export const QUERY_GET_GROUP_ACCUMULATED_ECONOMY_REPORT_BY_GROUP_ID = gql`
  query GetGroupAccumulatedEconomyReportByGroupID($groupId: ID!) {
    getGroupAccumulatedEconomyReportByGroupID(groupId: $groupId) {
      id
      group {
        id
        name
      }
      type
      createdAt
      totalSavings
      totalSavingsPercentage
      totalEnergyCost
      totalCarbonDioxideEmissionAvoided
      totalEnergyConsumption
      totalTreesSaved
      summaryEconomyReportsHistory {
        id
        monthlySavings
        monthlyEnergyCost
        monthlyCarbonDioxideEmissionAvoided
        monthlyEnergyConsumption
        monthlyTreesSaved
        date
      }
      unitsAccumulatedSummaryEconomyReports {
        unit {
          id
          name
        }
        accumulatedUnitsSavings
        accumulatedUnitsEnergyCost
        accumulatedUnitsCarbonDioxideEmissionAvoided
        accumulatedUnitsEnergyConsumption
        accumulatedUnitsSavingsPercentage
        accumulatedTreesSaved
      }
    }
  }
`;
