import { useMemo } from 'react';

import { useLazyQuery } from '@apollo/client';

import { GET_CONSOLIDATED_MEASUREMENT_BY_ID } from './query';
import { GetConsolidatedMeasurementByIdGraphQL } from './types';

export const useGetConsolidatedMeasurementById = () => {
  const [getConsolidatedMeasurementById, { loading, data }] = useLazyQuery<GetConsolidatedMeasurementByIdGraphQL>(
    GET_CONSOLIDATED_MEASUREMENT_BY_ID,
    {
      fetchPolicy: 'network-only',
    },
  );

  const getConsolidatedMeasurementByIdHandler = (id: string) => {
    getConsolidatedMeasurementById({
      variables: {
        id,
      },
    });
  };

  const measurements = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.getConsolidatedMeasurementById.datetimeMeasurement.filter((el) => {
      return el.subType == 'L';
    });
  }, [data]);

  return {
    loading,
    measurements,
    getConsolidatedMeasurementByIdHandler,
  };
};
