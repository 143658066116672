import { NuggetInfo } from '@clarke-energia/foton-v2';
import { MigrationState } from '@hooks/migration-report/use-migration-report-by-group/types';
import MultipleCardsContainer from '@components/atoms/layouts/multiple-cards-container';
import style from './style.module.css';

interface MigrationStateNuggetsProps {
  migratedUnits: number;
  migratingUnits: number;
  overdueUnits: number;
  onNuggetClick?: (state: MigrationState) => void;
}

const formatInfo = (info: number) => {
  if (!info) {
    return '0';
  }
  return info.toString().padStart(2, '0');
};

export const MigrationStateNuggets = (props: MigrationStateNuggetsProps) => {
  return (
    <MultipleCardsContainer gap={32} childMinWidth={336}>
      <div className={style.nuggetContainer} onClick={() => props.onNuggetClick?.(MigrationState.DONE)}>
        <NuggetInfo
          tooltip={{
            content: `Número de unidades que já foram migradas para o mercado livre.`,
            side: 'bottom',
            icon: {
              icon: 'InformationCircleIcon',
            },
          }}
          icon={{
            icon: 'CheckCircleIcon',
            extraClassNames: `bg-primary-20 text-[#00BF6A]`,
          }}
          title="Migrações concluídas"
          info={formatInfo(props.migratedUnits)}
          strongInfo
          className="w-full"
          sizes={{
            title: 14,
            info: 24,
            contentSpace: 12,
            icon: 24,
          }}
        />
      </div>
      <div className={style.nuggetContainer} onClick={() => props.onNuggetClick?.(MigrationState.IN_PROGRESS)}>
        <NuggetInfo
          tooltip={{
            content: `Número de unidades em migração para o mercado livre.`,
            side: 'bottom',
            icon: {
              icon: 'InformationCircleIcon',
            },
          }}
          icon={{
            icon: 'ClockIcon',
            extraClassNames: `bg-[#CFDFFC] text-[#0047CC]`,
          }}
          title="Migrações em dia"
          info={formatInfo(props.migratingUnits)}
          strongInfo
          className="w-full font-extrabold"
          sizes={{
            title: 14,
            info: 24,
            contentSpace: 12,
            icon: 24,
          }}
        />
      </div>
      <div className={style.nuggetContainer} onClick={() => props.onNuggetClick?.(MigrationState.OVERDUE)}>
        <NuggetInfo
          tooltip={{
            content: `Número de unidades em atraso para migração pro mercado livre.`,
            side: 'bottom',
            icon: {
              icon: 'InformationCircleIcon',
            },
          }}
          icon={{
            icon: 'ExclamationCircleIcon',
            extraClassNames: `bg-red-50 text-[#FF6347]`,
          }}
          title="Migrações em atraso"
          info={formatInfo(props.overdueUnits)}
          strongInfo
          className="w-full"
          sizes={{
            title: 14,
            info: 24,
            contentSpace: 12,
            icon: 24,
          }}
        />
      </div>
    </MultipleCardsContainer>
  );
};
