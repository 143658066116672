import { gql } from '@apollo/client';

export const QUERY_GET_UNITS_ECONOMY_REPORTS_BY_GROUP = gql`
  query GetSimpleUnitsEconomyReportsByGroupId($id: ID!) {
    unitsEconomyReportsByGroupId(id: $id) {
      id
      unit {
        id
        name
        contractedDemandOffPeak
        contractedDemandPeak
        unitType

        edc {
          id
        }
      }

      energyTotalConsumption
      energyTotalCost
      currentSavings
      savingsPercent
      tariffType
      date

      freeMarket {
        totalValue
        totalValueWithoutTax
        readDemandOffPeak
        readDemandPeak

        energyDistribution {
          reactivesTotal
        }
      }
      status
    }
  }
`;
