import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import { GET_UNIT_AVERAGE_CONSUMPTIONS } from './query';
import { GetUnitAverageConsumptionGraphQL } from './types';

export const useGetUnitAverageConsumptions = () => {
  const [yearlyConsumption, setYearlyConsumption] = useState(0);
  const [monthlyConsumption, setMonthlyConsumption] = useState(0);
  const [dailyConsumption, setDailyConsumption] = useState(0);

  const [getUnitAverageConsumptions, { loading, data }] = useLazyQuery<GetUnitAverageConsumptionGraphQL>(
    GET_UNIT_AVERAGE_CONSUMPTIONS,
    {
      fetchPolicy: 'network-only',
    },
  );

  const getUnitAverageConsumptionsHandler = (unitId: string) => {
    getUnitAverageConsumptions({
      variables: {
        unitId: unitId,
      },
    });
  };

  useEffect(() => {
    if (!data || !data.getUnitAverageConsumption) {
      setDailyConsumption(0);
      setMonthlyConsumption(0);
      setYearlyConsumption(0);
    } else {
      const { getUnitAverageConsumption } = data;

      setDailyConsumption(getUnitAverageConsumption.daily);
      setMonthlyConsumption(getUnitAverageConsumption.monthly);
      setYearlyConsumption(getUnitAverageConsumption.yearly);
    }
  }, [data]);

  return {
    getUnitAverageConsumptionsHandler,
    dailyConsumption,
    monthlyConsumption,
    yearlyConsumption,
    loading,
  };
};
