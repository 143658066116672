import React, { HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';
import Skeleton from 'react-loading-skeleton';
import { CardNumber } from '@clarke-energia/foton-v2';

import style from './style.module.css';
import { Card } from './helper';

export interface EconomyCardsProps extends HTMLAttributes<HTMLDivElement> {
  loading: boolean;
  cards: Card[];
  handleRedirect: (path: string) => void;
}

const Cards: React.FC<EconomyCardsProps> = ({ loading, cards, handleRedirect, ...props }) => {
  return (
    <>
      <p className={style.migrationTitleCards}>Enquanto sua migração não está completa, você pode visualizar:</p>
      <div {...props} className={style.migrationCardsContainer}>
        {cards.map((item, index) => {
          return loading ? (
            <Skeleton height={200} key={`iconCard-${index}`} />
          ) : (
            <CardNumber
              buttonIcon={{
                icon: 'ArrowUpRightIcon',
                kind: 'primary',
                size: 'small',
                onClick: () => handleRedirect(item.redirectLink),
                className: 'bg-primary-30',
              }}
              key={`iconCard-${index}`}
              title={item.title}
              value={item.value ?? '-'}
              variant={item.variant}
              icon={item.icon}
              ratio={item.ratio}
              className={twMerge('bg-white shadow-md', index === 1 && 'bg-opacity-[90%]')}
            />
          );
        })}
      </div>
    </>
  );
};

export default Cards;
