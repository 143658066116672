import { UnitEconomyReport } from '@hooks/economy/get-units-reports/types';
import { ConsumptionsByUnit } from '@hooks/consumption/use-units-consumptions-by-group/types';

import { formatDate, parseStringToDate } from '@utils/dayjs';

export interface ChartData {
  labels: string[];
  values: number[];
}

export const sortReportsByDate = (reports: UnitEconomyReport[]) => {
  return reports.sort((a, b) => {
    return Date.parse(a.date) - Date.parse(b.date);
  });
};

export const getDemandRequestData = (unitReports: UnitEconomyReport[]) => {
  if (unitReports && unitReports.length > 0) {
    const unit = unitReports[0].unit;

    const demandsMeasures: {
      measuredDemandPeak: number[];
      measuredDemandOffPeak: number[];
    } = {
      measuredDemandPeak: [],
      measuredDemandOffPeak: [],
    };
    for (const report of unitReports) {
      demandsMeasures.measuredDemandPeak.push(report.freeMarket.readDemandPeak);
      demandsMeasures.measuredDemandOffPeak.push(report.freeMarket.readDemandOffPeak);
    }

    return {
      contractedDemandOffPeak: unit.contractedDemandOffPeak,
      contractedDemandPeak: unit.contractedDemandPeak,
      energyDistributionCompany: unit.edc.id,
      icmsType: unit.unitType,
      tariff: unitReports[0].tariffType,
      ...demandsMeasures,
    };
  }
  return null;
};

export const getUnitsConsumptionsMonth = (consumptionsByUnit: ConsumptionsByUnit | undefined) => {
  const monthsByUnit: Record<string, string[]> = {};
  if (consumptionsByUnit) {
    for (const consumptionUnitId of Object.keys(consumptionsByUnit ?? [])) {
      const unitsConsumptions = consumptionsByUnit[consumptionUnitId].consumptions;
      monthsByUnit[consumptionUnitId] = unitsConsumptions.map((el) => {
        return el.referenceMonthYear;
      });
    }
  }
  return monthsByUnit;
};

export const parseMeasureDate = (date: string) => {
  return formatDate(parseStringToDate(date), 'DD/MM/YYYY');
};
