import { gql } from '@apollo/client';

export const GET_GROUP_QUERY = gql`
  query Group($groupId: ID!) {
    group(id: $groupId) {
      id
      units {
        id
        name
        unitType
        totalConsumptionVolume
      }
    }
  }
`;

export const GET_ALL_GROUPS = gql`
  query GetAllGroups {
    groups {
      data {
        id
        name
      }
    }
  }
`;
