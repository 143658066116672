import {
  IConsumptionProjection,
  IDateProjection,
  IGroupConsumptionProjection,
  ITotalMonthlyConsumption,
  MonthlyControlMeasurementByUnitId,
  MonthlyGroupControlMeasurement,
} from '@contexts/consumption/types';
import { triggerAmplitudeButtonClickedEvent } from '@services/event-tracking/events-trigger';
import { convertNumberToMonth } from '@utils/constants/common';
import { getLastDayMonth } from '@utils/text';
import dayjs from 'dayjs';
import { CLARKE_CUSTOMER_GROUP_ID } from './consumption-static-data';

function sumActiveConsumption<T extends MonthlyControlMeasurementByUnitId | MonthlyGroupControlMeasurement>(
  unitConsumptions: T[] | [],
) {
  let sum = 0;

  if (!unitConsumptions || unitConsumptions.length === 0) return sum;
  unitConsumptions.forEach((item) => {
    sum += item.totalConsumption;
  });
  const sumInKwh = sum * 1000;
  return sumInKwh;
}

export default sumActiveConsumption;

export const dateMeasurement = (controlDocument: MonthlyControlMeasurementByUnitId[] | undefined) => {
  if (controlDocument) {
    const sortedControlDoc = controlDocument.sort(
      (current, next) => dayjs(current.date).unix() - dayjs(next.date).unix(),
    );
    const firstDateDay = sortedControlDoc[0]?.date.substring(8, 10) ?? '-';
    const lastDateDay = sortedControlDoc[sortedControlDoc.length - 1]?.date.substring(8, 10) ?? '-';
    const month = sortedControlDoc[0]?.date.substring(5, 7);
    const nameMonth = convertNumberToMonth(month) ?? '-';

    return `${firstDateDay} - ${lastDateDay} de ${nameMonth}`;
  }
};

export const autoFillConsumptionProjection = (consumptionProjection: IDateProjection[]) => {
  const lastDayDateMonth = getLastDayMonth();
  const lastDayFromDate = Number(lastDayDateMonth.substring(0, 2)) - 1;

  const missingItens = lastDayFromDate - consumptionProjection.length;
  if (missingItens > 0) {
    const fillProjectionData = new Array(missingItens).fill(0);
    return fillProjectionData.concat(consumptionProjection);
  }
  return consumptionProjection;
};

export const totalMonthConsumptionProjection = (
  consumptionProjection: IDateProjection[],
  totalMonthlyConsumption: number,
  daysWithMeasures: number,
) => {
  let totalProjection = 0;
  const consumptionProjectionFilled = autoFillConsumptionProjection(consumptionProjection);
  consumptionProjectionFilled.slice(daysWithMeasures).forEach((item) => {
    totalProjection += Number(item.consumptionPredictedConstrained);
  });
  const totalProjectionInKwh = totalProjection * 1000;
  return totalProjectionInKwh + totalMonthlyConsumption;
};

export function filteredConsumptionOrderedByDate<
  T extends MonthlyControlMeasurementByUnitId | MonthlyGroupControlMeasurement,
>(monthlyControlMeasurement: T[]): T[] {
  const filteredConsumptionOrderedByDate = monthlyControlMeasurement.filter((measure) => {
    return measure.totalConsumption > 0;
  });

  return filteredConsumptionOrderedByDate;
}

export function filterAndOrderDateList<T extends MonthlyControlMeasurementByUnitId | MonthlyGroupControlMeasurement>(
  monthlyControlMeasurement: T[],
) {
  const consumptionOrderedByDate = monthlyControlMeasurement?.sort((a, b) => {
    const dataA = new Date(a.date);
    const dataB = new Date(b.date);
    return dataB < dataA ? -1 : dataB > dataA ? 1 : 0;
  });

  return filteredConsumptionOrderedByDate(consumptionOrderedByDate);
}

export function filterAndOrderDateListInReverse<
  T extends MonthlyControlMeasurementByUnitId | MonthlyGroupControlMeasurement,
>(monthlyControlMeasurement: T[]) {
  const consumptionOrderedByDate = monthlyControlMeasurement
    ?.sort((a, b) => {
      const dataA = new Date(a.date);
      const dataB = new Date(b.date);
      return dataB < dataA ? -1 : dataB > dataA ? 1 : 0;
    })
    .reverse();

  return filteredConsumptionOrderedByDate(consumptionOrderedByDate);
}

export function lastDateWithMeasurement<T extends MonthlyControlMeasurementByUnitId | MonthlyGroupControlMeasurement>(
  monthlyControlMeasurement: T[],
): T | undefined {
  const filterMissingMeasurement = filterAndOrderDateListInReverse(monthlyControlMeasurement);
  return filterMissingMeasurement.pop();
}

export const getMonthYear = (actualMonth = 1) => {
  const rawDate = new Date();
  const pageMonth = (rawDate.getMonth() + actualMonth).toString();
  const year = rawDate.getFullYear().toString();
  return pageMonth.padStart(2, '0') + '/' + year;
};

export const getMonthYearLabel = (monthYear: string) => {
  const nameMonth = convertNumberToMonth(monthYear.substring(0, 2)) ?? '-';
  return `${nameMonth} ${monthYear.substring(3)}`;
};

export const getActualMonth = (groupId?: string): number => {
  return groupId === CLARKE_CUSTOMER_GROUP_ID ? 0 : 1;
};

export const projectionAtMissingHours = (
  controlDocs: MonthlyControlMeasurementByUnitId[],
  projectionDocs: IDateProjection[],
) => {
  controlDocs.forEach((control) => {
    if (control.totalConsumption === 0) {
      const projectionDoc = projectionDocs.filter((projection) => control.date === projection.date);
      control.totalConsumption = Number(projectionDoc[0].consumptionPredictedConstrained);
    }
  });
};

export function ConsumptionHandlerHelper<T extends MonthlyControlMeasurementByUnitId | MonthlyGroupControlMeasurement>(
  monthlyControlMeasurement: T[],
) {
  const cceeConsumption = sumActiveConsumption(monthlyControlMeasurement);
  const averageConsumption = cceeConsumption / monthlyControlMeasurement?.length;

  return {
    total: cceeConsumption,
    average: averageConsumption,
  };
}

export function ProjectionHandlerHelper<T extends IConsumptionProjection | IGroupConsumptionProjection>(
  projection: T,
  controlDocsLength: number,
  totalMonthlyConsumption: number | undefined,
) {
  const lastDayDateMonth = getLastDayMonth();
  const lastDayFromDate = Number(lastDayDateMonth.substring(0, 2));

  if (projection.dailyConsumptionProjection.length === lastDayFromDate) {
    const totalProjection = projection.monthlyConsumptionProjection;
    // will be removed
    return parseFloat(totalProjection.toString()) * 1000;
  }

  if (totalMonthlyConsumption) {
    return totalMonthConsumptionProjection(
      projection.dailyConsumptionProjection,
      totalMonthlyConsumption,
      controlDocsLength,
    );
  }
}

export function transformDateToMonthYear(dateString: string) {
  const [year, month] = dateString.split('-');
  const formattedDate = `${month}/${year}`;
  return formattedDate;
}

export function includeCurrentMonthOnFirstDay(totalMonthlyConsumption: ITotalMonthlyConsumption[]) {
  if (totalMonthlyConsumption.slice(-1)[0].referenceMonthYear !== getMonthYear()) {
    totalMonthlyConsumption.push({
      referenceMonthYear: getMonthYear(),
      consumption: 0,
      unitId: '',
      averageConsumption: 0,
    });
  }
  return totalMonthlyConsumption;
}

export function transformMonthYearToDate(monthYear: string | undefined) {
  if (monthYear) {
    const date = dayjs(monthYear, 'MM/YYYY');
    const formattedDate = date.format('YYYY-MM-DD');
    return formattedDate;
  }
}
export const triggerButtonEvent = (buttonText: string, destinationPath?: string) => {
  return triggerAmplitudeButtonClickedEvent('Consumo', buttonText, destinationPath);
};
