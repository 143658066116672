import { gql } from '@apollo/client';

export const GET_ALL_TOTAL_MONTHLY_CONSUMPTION_BY_UNITS = gql`
  query GetAllTotalUnitConsumptions($unitIds: [ID!]!) {
    getAllTotalUnitConsumptions(unitIds: $unitIds) {
      id
      unitId
      consumption
      referenceMonthYear
      averageConsumption
    }
  }
`;
