import { LineChart } from '@clarke-energia/foton-v2';
import ChartContainer from '@components/molecules/charts/chart-container';
import { UnitConsumption } from '@hooks/consumption/use-units-consumptions-by-group/types';
import { parseConsumptionHistoryChartByUnit } from './parses';

interface ConsumptionHistoryByUnitChartProps {
  title: string;
  consumptions: UnitConsumption[];
}

const ConsumptionHistoryByUnitChart: React.FC<ConsumptionHistoryByUnitChartProps> = ({
  title,
  consumptions,
}: ConsumptionHistoryByUnitChartProps) => {
  const { labels, series } = parseConsumptionHistoryChartByUnit(consumptions);

  return (
    <ChartContainer
      title={{
        text: title,
      }}
      className=""
      tooltipContent="Histórico de consumo por unidade consumidora"
      export={{
        name: 'historico-de-consumo-por-unidade',
      }}
    >
      <LineChart
        labels={labels}
        series={series}
        lines={[]}
        options={{
          legend: { show: true },
          suffix: ' MWh',
          autoScale: true,
        }}
      />
    </ChartContainer>
  );
};

export default ConsumptionHistoryByUnitChart;
