import React, { useMemo } from 'react';
import { LineChart } from '@clarke-energia/foton-v2';

import MobileLikeIconButton from '@components/atoms/buttons/mobile-like-icon-button';
import SustainabilityCertificates from '@components/organisms/sustainability/sustainability-certificates';
import SustainabilityNuggets from '@components/organisms/sustainability/sustainability-nuggets';
import Layout from '@components/templates/screen-layout';
import { HomeSkeleton } from '@components/molecules/skeleton/home-skeleton';

import { useIsMobile } from '@hooks/is-mobile';
import { useUserInfo } from '@hooks/use-user-info';
import { useExportElementAsImage } from '@hooks/export-as-image';
import { parseSustainabilityReportCharts } from '@hooks/economy/parser';
import { useGetGroupAccumulatedEconomyReportByGroupId } from '@hooks/economy/get-group-accumulated-economy-report-by-group-id';

import { HOME_PATH, SUSTAINABILITY_PATH } from '@routers/constants';

import EmptyData from '@components/molecules/empty-data';

import style from './style.module.css';

const SustainabilityHub: React.FC = () => {
  const exportRef = React.useRef<HTMLDivElement>(null);
  const { user, isFetchingUser } = useUserInfo();
  const exportElementAsImage = useExportElementAsImage();
  const { getGroupAccumulatedEconomyReportByGroupId, data, parsedData, loading } =
    useGetGroupAccumulatedEconomyReportByGroupId();

  const isMobile = useIsMobile();

  const pageTitle = isMobile ? 'Sustentabilidade' : 'Relatório de Sustentabilidade';

  useMemo(() => {
    if (user?.group) {
      getGroupAccumulatedEconomyReportByGroupId({
        variables: {
          groupId: user.group.id,
        },
      });
    }
  }, [user]);

  const summaryEconomyHistoryCharts = useMemo(() => {
    return parseSustainabilityReportCharts(parsedData?.rawSummaryEconomyReportsHistory ?? []);
  }, [parsedData]);

  if (isFetchingUser || loading) {
    return (
      <Layout.Content
        header={{
          title: '',
          displayDesktop: true,
        }}
      >
        <HomeSkeleton />
      </Layout.Content>
    );
  }

  if (!data) {
    return (
      <Layout.Content
        extraContentClassName="h-screen"
        header={{
          title: pageTitle,
          displayDesktop: true,
          breadCrumbLinks: [
            {
              label: 'Home',
              url: HOME_PATH,
            },
            {
              label: 'Relatório de Sustentabilidade',
              url: SUSTAINABILITY_PATH,
            },
          ],
        }}
      >
        <EmptyData description="A migração para o Mercado Livre ainda não foi concluída. Você encontrará os relatórios de sustentabilidade assim que concluir o processo de migração" />
      </Layout.Content>
    );
  }

  return (
    <Layout.Content
      header={{
        title: pageTitle,
        displayDesktop: true,
        breadCrumbLinks: [
          {
            label: 'Home',
            url: HOME_PATH,
          },
          {
            label: 'Relatório de Sustentabilidade',
            url: SUSTAINABILITY_PATH,
          },
        ],
        children: (
          <MobileLikeIconButton
            size="medium"
            leftIcon="ArrowDownTrayIcon"
            onClick={async () => {
              if (!exportRef.current) return;
              await exportElementAsImage({ element: exportRef.current, exportName: 'relatorio-de-sustentabilidade' });
            }}
            className="mr-2"
          >
            Exportar
          </MobileLikeIconButton>
        ),
      }}
    >
      <div className={style.pageContainer} ref={exportRef}>
        <div className={style.nuggetsContainer}>
          <SustainabilityNuggets
            totalCo2Avoided={
              parsedData.parsedGroupAccumulatedEconomyReport?.totalCarbonDioxideEmissionAvoided ??
              'Informação Indisponível'
            }
            totalTreesSaved={
              parsedData.parsedGroupAccumulatedEconomyReport?.totalTreesSaved ?? 'Informação indisponível'
            }
          />
        </div>

        <div className={style.chartContainer}>
          <div>
            <LineChart
              title="Toneladas de CO2 evitadas"
              labels={summaryEconomyHistoryCharts?.CO2AvoidedHistory?.labels ?? []}
              series={[
                {
                  name: 'Toneladas de CO² evitadas',
                  // TODO: add in foton-v2 a possibility of null values
                  values: summaryEconomyHistoryCharts?.CO2AvoidedHistory?.values?.map((value) => value ?? 0) ?? [],
                  color: '#96E9B6',
                },
              ]}
              lines={[]}
              options={{
                legend: { show: true },
                rightSpace: 2,
                leftSpace: 55,
              }}
            />
          </div>
        </div>

        <div className={style.certificatesContainer}>
          <SustainabilityCertificates />
        </div>
      </div>
    </Layout.Content>
  );
};

export default SustainabilityHub;
