import { useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';

import { useUserInfo } from '@hooks/use-user-info';
import { useGetGroup as useGetGroupData } from '@hooks/get-group';
import { ITotalMonthlyConsumption } from '@contexts/consumption';

import { ConsumptionsByUnit } from './types';
import { GET_ALL_TOTAL_MONTHLY_CONSUMPTION_BY_UNITS } from './queries';

export const useUnitsConsumptionsByGroup = () => {
  const { user, isFetchingUser } = useUserInfo();
  const { getGroup, data: groupData, loading: loadingGroupData } = useGetGroupData();
  const [getConsumptionsByUnits, { loading, data }] = useLazyQuery<{
    getAllTotalUnitConsumptions: ITotalMonthlyConsumption[];
  }>(GET_ALL_TOTAL_MONTHLY_CONSUMPTION_BY_UNITS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (user?.group && (!groupData || groupData.group.id !== user.group.id)) {
      getGroup(user.group.id);
    }
  }, [user]);

  useEffect(() => {
    if (groupData?.group && !loading) {
      getConsumptionsByUnits({
        variables: {
          unitIds: groupData.group.units.map((unit) => unit.id),
        },
      });
    }
  }, [groupData]);

  const getConsumptions = () => {
    if (!data || !groupData) return;
    let unitsById = {};
    for (const unit of groupData.group.units) {
      unitsById = {
        ...unitsById,
        [unit.id]: unit,
      };
    }

    const unitConsumption = data.getAllTotalUnitConsumptions.reduce((acc: ConsumptionsByUnit, unitConsumption) => {
      if (!acc[unitConsumption.unitId]) {
        return {
          ...acc,
          [unitConsumption.unitId]: {
            id: unitConsumption.unitId,
            // @ts-expect-error - the units are get by the groupData
            name: unitsById[unitConsumption.unitId].name,
            consumptions: [unitConsumption],
          },
        };
      }

      return {
        ...acc,
        [unitConsumption.unitId]: {
          ...acc[unitConsumption.unitId],
          consumptions: [...acc[unitConsumption.unitId].consumptions, unitConsumption],
        },
      };
    }, {});

    for (const unitId in unitConsumption) {
      unitConsumption[unitId].consumptions.sort((a, b) => {
        const [aMonth, aYear] = a.referenceMonthYear.split('/');
        const [bMonth, bYear] = b.referenceMonthYear.split('/');
        if (aYear === bYear) {
          return parseInt(aMonth) - parseInt(bMonth);
        }
        return parseInt(aYear) - parseInt(bYear);
      });
    }

    return unitConsumption;
  };

  const consumptions: ConsumptionsByUnit | undefined = useMemo(() => getConsumptions(), [data]);

  return {
    loading: loading || loadingGroupData || isFetchingUser,
    data,
    consumptions,
  };
};
