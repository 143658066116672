import React from 'react';

import { EconomyBarChartsProps } from '@components/molecules/dashboard/types';
import { DashboardEconomyChart } from '@components/molecules/dashboard/economy-chart';
import { DashboardCostChart } from '@components/molecules/dashboard/cost-chart';

const BarCharts: React.FC<EconomyBarChartsProps> = ({ economyChart, costChart, ...props }) => {
  return (
    <div {...props} className="grid grid-cols-3 gap-6 mt-6 lg:gap-9 lg:mt-9">
      <DashboardEconomyChart {...economyChart} ref={economyChart.ref} />
      <DashboardCostChart {...costChart} ref={costChart.ref} />
    </div>
  );
};

export default BarCharts;
