import { LineChartProps } from '@clarke-energia/foton-v2';
import { UnitConsumption } from '@hooks/consumption/use-units-consumptions-by-group/types';
import { GreenColorPicker } from '@utils/colors';

const sortLabels = (labels: string[]) => {
  return labels.sort((a, b) => {
    const [aMonth, aYear] = a.split('/');
    const [bMonth, bYear] = b.split('/');

    if (aYear === bYear) {
      return parseInt(aMonth) - parseInt(bMonth);
    }

    return parseInt(aYear) - parseInt(bYear);
  });
};

export const parseConsumptionHistoryChartByUnit = (
  consumptionsByUnit: UnitConsumption[],
): Omit<LineChartProps, 'lines' | 'options' | 'title'> => {
  let labels: string[] = [];
  const series: LineChartProps['series'] = [];
  const colorManager = new GreenColorPicker();

  for (const unit of consumptionsByUnit) {
    const valuesByLabel = unit.consumptions.reduce((acc, consumption) => {
      if (!labels.includes(consumption.referenceMonthYear)) {
        labels.push(consumption.referenceMonthYear);
      }

      return {
        ...acc,
        [consumption.referenceMonthYear]: consumption.consumption,
      };
    });

    const color = colorManager.pickOne();

    const serie = {
      name: unit.name,
      values: [],
      valuesByLabel,
      color: color,
    };

    series.push(serie);
  }

  labels = sortLabels(labels);

  for (const label of labels) {
    for (const serie of series) {
      // @ts-expect-error - the valuesByLabel is set in the previous loop
      serie.values.push(serie.valuesByLabel[label] || 0);
    }
  }

  return { labels, series };
};
