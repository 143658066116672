import React, { useContext, useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { Link } from 'react-router-dom';
import { Menu, MenuProps } from '@clarke-energia/foton-v2';

import { authContext } from '@src/ApolloWrapper';
import { useUserInfo } from '@hooks/use-user-info';
import { useMenuItemActive } from '@hooks/menu-item-active';
import { useGetGroups } from '@hooks/get-group/get-groups';
import { useAuth0 } from '@auth0/auth0-react';

import { IDecodedToken } from '@contexts/users/types';
import { BASE_MENU_ITENS } from './helper';

import { HOME_PATH } from '@routers/constants';
import { InputOptionProps } from '@utils/types';
import style from './style.module.css';

export interface SidebarProps {
  menuColor?: MenuProps['menuColor'];
  collapsed?: MenuProps['collapsed'];
  showCollapsedButton?: MenuProps['showCollapsedButton'];
}

const Sidebar: React.FC<SidebarProps> = ({ collapsed = true, menuColor = 'default', showCollapsedButton = true }) => {
  const { user, upsertGroupRelationWithUserHandler, isUpsertRelationSuccess, groupsByUser } = useUserInfo();

  const { logout } = useAuth0();
  const { data, loading } = useGetGroups();
  const { authStatus } = useContext(authContext);
  const decodedToken: IDecodedToken = jwtDecode(authStatus.accessToken);

  const isSuperAdmin = decodedToken.scope.split(' ').includes('switch:customer');

  const [inputOptions, setInputOptions] = useState<InputOptionProps[]>([]);

  const onGroupSelected = (groupId: string) => {
    upsertGroupRelationWithUserHandler(groupId);
  };

  useEffect(() => {
    if (isUpsertRelationSuccess) {
      window.location.replace(HOME_PATH);
    }
  }, [isUpsertRelationSuccess]);

  useEffect(() => {
    if (data?.groups.data || groupsByUser) {
      const localGroups = isSuperAdmin ? data?.groups.data : groupsByUser;
      const localInputOptions = localGroups
        ? localGroups.map((group) => {
            return {
              label: group.name,
              value: group.id,
            };
          })
        : [];
      setInputOptions(localInputOptions);
    }
  }, [data?.groups.data, groupsByUser]);

  const menuItems = BASE_MENU_ITENS.map((section) => ({
    ...section,
    links: section.links.map((item) => ({
      ...item,
      isActive: useMenuItemActive(item.path),
      LinkComponent: Link,
    })),
  })) as MenuProps['menuItem'];

  return (
    <aside className={style.container}>
      <Menu
        className={menuColor === 'green' ? 'bg-inherit' : 'bg-white'}
        handleLogout={logout}
        userInfo={{
          name: user.name,
          email: user.email,
          picture: { src: user?.photoUrl },
        }}
        menuItem={menuItems}
        menuColor={menuColor}
        showCollapsedButton={showCollapsedButton}
        groupListSelect={{
          options: inputOptions,
          value: user.group?.id,
          onChange: (value: string) => onGroupSelected(value),
          placeholder: loading ? 'Carregando...' : 'Selecione o grupo',
        }}
        collapsed={collapsed}
      />
    </aside>
  );
};

export default Sidebar;
