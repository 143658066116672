import { BarChartProps } from '@clarke-energia/foton-v2';
import { SimpleConsumption } from '@contexts/consumption';
import { colors } from '@utils/theme';

export const consumptionsParse = (
  consumptions: SimpleConsumption[],
  group: string,
): Omit<BarChartProps, 'lines' | 'options' | 'title'> => {
  const labels = consumptions.map((consumption) => consumption.referenceMonthYear);
  const series: BarChartProps['series'] = [
    {
      name: group,
      data: consumptions.map((consumption) => consumption.consumption),
      color: colors.primary[0],
    },
  ];

  return {
    labels,
    series,
  };
};
