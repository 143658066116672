import { IconButton, Tag, ProgressBar, IconName } from '@clarke-energia/foton-v2';
import { twMerge } from 'tailwind-merge';
import { customColors } from '../../../../../tailwind.config';
import { MigrationState } from '@hooks/migration-report/use-migration-report-by-group/types';

import style from './style.module.css';

interface UnitProgressProps {
  state: MigrationState;
  name: string;
  groupName: string;
  dueDate: string | null;
  progress: number;
  stepsInfo: {
    completed: number;
    total: number;
  };
  viewClick?: () => void;
}

const MapProgressState: Record<
  UnitProgressProps['state'],
  { bar: string; badge: string; text: string; icon: IconName }
> = {
  OVERDUE: {
    bar: customColors.progressWarning,
    badge: 'bg-orange-10 text-orange-30',
    text: 'Em atraso',
    icon: 'ExclamationCircleIcon',
  },
  IN_PROGRESS: {
    bar: customColors.progressSecondary,
    badge: 'bg-[#CFDFFC] text-[#0047CC]',
    text: 'Em andamento',
    icon: 'ClockIcon',
  },
  DONE: {
    bar: customColors.progressPrimary,
    badge: 'bg-primary-10 text-primary-70',
    text: 'Concluído',
    icon: 'CheckCircleIcon',
  },
};

const UnitProgress = (props: UnitProgressProps) => {
  const mapData = MapProgressState[props.state];

  return (
    <div className={style.container}>
      <div className={style.content}>
        <div className={style.header}>
          <div>
            <p className={style.unitName}>{props.name}</p>
            <Tag
              label={mapData.text}
              kind="icon"
              className={twMerge(style.tag, mapData.badge)}
              icon={{
                icon: mapData.icon,
              }}
            />
          </div>
          <span onClick={props.viewClick}>
            <IconButton icon="EyeIcon" size="small" />
          </span>
        </div>

        <div>
          <p className={style.info}>Empresa contratante: {props.groupName}</p>
          {props.dueDate && <p className={style.info}>Data prevista para migração: {props.dueDate}</p>}
          <p className={style.info}>
            Etapas cumpridas: {props.stepsInfo.completed} de {props.stepsInfo.total}
          </p>
        </div>
      </div>

      <div>
        <div>
          <ProgressBar
            value={props.progress}
            size="large"
            label={{
              position: 'right',
            }}
            color={MapProgressState[props.state].bar}
          />
        </div>
      </div>
    </div>
  );
};

export default UnitProgress;
