import { useMemo } from 'react';

import { useLazyQuery } from '@apollo/client';

import { GetUnitControlMeasurementsByUnitIdAndMonthYearGraphQL } from './types';
import { GET_UNIT_CONTROL_MEASUREMENTS_BY_UNIT_ID_AND_MONTH_YEAR } from './queries';

export const useGetUnitControlMeasurementsByUnitIdAndMonthYear = () => {
  const [getUnitControlMeasurementsByUnitIdAndMonthYear, { loading, data }] =
    useLazyQuery<GetUnitControlMeasurementsByUnitIdAndMonthYearGraphQL>(
      GET_UNIT_CONTROL_MEASUREMENTS_BY_UNIT_ID_AND_MONTH_YEAR,
      {
        fetchPolicy: 'network-only',
      },
    );

  const getUnitControlMeasurementsByUnitIdAndMonthYearHandler = (unitId: string, monthYear: string) => {
    getUnitControlMeasurementsByUnitIdAndMonthYear({
      variables: {
        input: {
          unitId: unitId,
          monthYear: monthYear,
        },
      },
    });
  };

  const unitMonthConsumptions = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.getMonthlyControlMeasurementByUnitId.sort((a, b) => {
      return a.date.localeCompare(b.date);
    });
  }, [data]);

  return {
    getUnitControlMeasurementsByUnitIdAndMonthYearHandler,
    unitMonthConsumptions,
    loading,
  };
};
