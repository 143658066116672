import { ITotalMonthlyConsumption, SimpleConsumption } from '@contexts/consumption';
import { ConsumptionsByUnit } from '@hooks/consumption/use-units-consumptions-by-group/types';
import { UnitEconomyReport } from '@hooks/economy/get-units-reports/types';
import { unifyReportsByUnit } from '@hooks/economy/get-units-reports/utils';
import { SummaryEconomyReportsHistory } from '@hooks/economy/types';
import { formatMonthAndYearDate } from '@utils/date';

export const parseUnitReportsToConsumptionsByUnit = (unitReports: UnitEconomyReport[]): ConsumptionsByUnit => {
  const reportsByUnits = unifyReportsByUnit(unitReports || []);
  const consumptionsByUnitReports: ConsumptionsByUnit = {};
  for (const unitId of Object.keys(reportsByUnits)) {
    const reports = reportsByUnits[unitId];
    const consumptions: ITotalMonthlyConsumption[] = reports.map((report) => ({
      referenceMonthYear: formatMonthAndYearDate(report.date),
      consumption: report.energyTotalConsumption,
      averageConsumption: 0,
      unitId: report.unit.id,
    }));
    consumptionsByUnitReports[unitId] = {
      id: unitId,
      name: reportsByUnits[unitId][0].unit.name,
      consumptions,
    };
  }
  return consumptionsByUnitReports;
};

export const parseGroupReportsToConsumptions = (reports: SummaryEconomyReportsHistory[]): SimpleConsumption[] => {
  return reports.map((report) => ({
    referenceMonthYear: formatMonthAndYearDate(report.date),
    consumption: report.monthlyEnergyConsumption,
  }));
};
