import { DonutChart, DonutChartProps } from '@clarke-energia/foton-v2';
import ChartContainer from '@components/molecules/charts/chart-container';
import { UnitConsumption } from '@hooks/consumption/use-units-consumptions-by-group/types';

const parseChartData = (consumptions: UnitConsumption[], targetMonthYear: string): DonutChartProps['data'] => {
  const chartData: DonutChartProps['data'] = [];
  for (const unitConsumption of consumptions) {
    const targetConsumption = unitConsumption.consumptions.find(
      (consumption) => consumption.referenceMonthYear === targetMonthYear,
    );
    if (!targetConsumption) continue;
    chartData.push({
      name: unitConsumption.name,
      value: parseFloat(targetConsumption.consumption.toFixed(2)),
    });
  }
  return chartData;
};

interface DonutConsumptionByUnitChartProps {
  title: string;
  consumptions: UnitConsumption[];
  monthYear: string;
}

const DonutConsumptionByUnitChart = ({ title, consumptions, monthYear }: DonutConsumptionByUnitChartProps) => {
  return (
    <ChartContainer
      title={{
        text: title,
      }}
      className=""
      tooltipContent="Consumo mensal das Unidades Consumidoras"
    >
      <div className="h-full h-[90%]">
        <DonutChart data={parseChartData(consumptions, monthYear)} suffix=" MWh" autoScale />
      </div>
    </ChartContainer>
  );
};

export default DonutConsumptionByUnitChart;
