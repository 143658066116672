import { gql } from '@apollo/client';

export const GET_ANNUAL_CONSUMPTION_PROJECTION_BY_UNIT_ID = gql`
  query GetAnnualConsumptionProjectionByUnitId($unitId: ID!) {
    getAnnualConsumptionProjectionByUnitId(unitId: $unitId) {
      unitId
      year
      projectedConsumption

      lastYearsConsumptions {
        year
        consumption
      }
    }
  }
`;
