import { CardNumberProps } from '@clarke-energia/foton-v2';

import { ITotalMonthlyConsumption } from '@contexts/consumption';

import { UnitEconomyReport } from '@hooks/economy/get-units-reports/types';
import { getRatio } from '@utils/math';

import { formatCurrency } from '@utils/text';

export const getUnitHourlyConsumption = () => {
  const hourlyConsumption: {
    labels: string[];
    values: number[];
  } = {
    labels: [],
    values: [],
  };

  for (let i = 0; i < 24; i++) {
    hourlyConsumption.labels.push(i + '');
    hourlyConsumption.values.push(Math.random() * 100);
  }
  return hourlyConsumption;
};

export const getUnitDailyConsumption = () => {
  const dailyConsumption: {
    labels: string[];
    values: {
      projected: number[];
      consolidated: number[];
    };
  } = {
    labels: [],
    values: {
      projected: [],
      consolidated: [],
    },
  };

  for (let i = 1; i < 31; i++) {
    dailyConsumption.labels.push(i + '');

    if (i < 15) {
      dailyConsumption.values.consolidated.push(Math.random() * 100);
      dailyConsumption.values.projected.push(0);
    } else {
      dailyConsumption.values.consolidated.push(0);
      dailyConsumption.values.projected.push(Math.random() * 100);
    }
  }

  return dailyConsumption;
};

const getDirection = (initial: number, final: number) => {
  if (initial > final) {
    return 'down';
  } else if (initial < final) {
    return 'up';
  }
  return 'none';
};

export const getMonthlyConsumptionInformation = ({
  consumption,
  report,
}: {
  consumption: {
    previous?: ITotalMonthlyConsumption;
    current?: ITotalMonthlyConsumption;
  };
  report: {
    previous?: UnitEconomyReport;
    current?: UnitEconomyReport;
  };
}): CardNumberProps[] => {
  const getAverageConsumption = (value: number) => {
    return value == -1 ? 0 : value;
  };

  const currentAverageConsumption = consumption.current
    ? getAverageConsumption(consumption.current.averageConsumption)
    : 0;

  const previousAverageConsumption = consumption.previous
    ? getAverageConsumption(consumption.previous.averageConsumption)
    : 0;

  const averageRatio = getRatio(previousAverageConsumption, currentAverageConsumption);

  const previousConsumption = consumption.previous?.consumption ?? 0;
  const currentConsumption = consumption.current?.consumption ?? 0;
  const consumptionRatio = getRatio(previousConsumption, currentConsumption);

  const previousReactives = report.previous?.freeMarket.energyDistribution.reactivesTotal ?? 0;
  const currentReactives = report.current?.freeMarket.energyDistribution.reactivesTotal ?? 0;
  const reactivesRatio = getRatio(previousReactives, currentReactives);

  return [
    {
      icon: {
        name: 'LightBulbIcon',
      },
      title: 'Consumo total',
      value: `${consumption.current?.consumption.toFixed(2) ?? '-'} MWh`,
      variant: 'normal',
      ratio: {
        value: (consumptionRatio * 100).toFixed(1) + ' %',
        direction: getDirection(previousConsumption, currentConsumption),
      },
    },
    {
      icon: {
        name: 'CalendarDaysIcon',
      },
      title: 'Consumo médio',
      value: `${currentAverageConsumption.toFixed(3) ?? '-'} MWh`,
      variant: 'normal',
      ratio: {
        value: (averageRatio * 100).toFixed(1) + ' %',
        direction: getDirection(previousAverageConsumption, currentAverageConsumption),
      },
    },
    {
      icon: {
        name: 'BanknotesIcon',
      },
      title: 'Reativos do mês',
      value: `${formatCurrency(currentReactives)}`,
      variant: 'normal',
      ratio: {
        value: (reactivesRatio * 100).toFixed(1) + ' %',
        direction: getDirection(previousReactives, currentReactives),
      },
    },
    {
      icon: {
        name: 'BoltIcon',
      },
      title: 'Demanda lida / contratada',
      value: `${report.current?.freeMarket.readDemandOffPeak ?? '-'} kW / ${
        report.current?.unit.contractedDemandOffPeak ?? '-'
      } kW`,
      variant: 'normal',
    },
  ];
};
