import { CardNumberProps } from '@clarke-energia/foton-v2';
import { EconomyReportIconCardData, EconomyReportIconCardDataItem } from '@pages/economy-reports/types';
import {
  CONSUMPTION_DASHBOARD_PATH,
  CONTRACTS_PATH,
  ECONOMY_REPORT_PATH,
  MIGRATION_REPORT_PATH,
} from '@routers/constants';

export interface Card extends CardNumberProps {
  redirectLink: string;
}

export const getRatio = (data: EconomyReportIconCardDataItem) => {
  return {
    value: data.percentage ?? '',
    direction: data.direction ?? 'none',
  };
};

export const buildCardNumberProps = (data: EconomyReportIconCardData): Card[] => {
  const extraParams = {
    colors: {
      icon: 'text-primary-70',
      background: 'bg-primary-20',
    },
    solid: true,
  };

  return [
    {
      icon: {
        name: 'CurrencyDollarIcon',
        ...extraParams,
      },
      title: 'Economia do último mês',
      value: data.economy.total,
      ratio: getRatio(data.economy),
      variant: 'normal',
      redirectLink: ECONOMY_REPORT_PATH,
    },
    {
      icon: {
        name: 'BanknotesIcon',
        ...extraParams,
      },
      title: 'Custo do último mês',
      value: data.energy.cost.total,
      ratio:
        data.energy.cost.direction && data.energy.cost.direction !== 'none' ? getRatio(data.energy.cost) : undefined,
      variant: 'normal',
      redirectLink: ECONOMY_REPORT_PATH,
    },
    {
      icon: {
        name: 'LightBulbIcon',
        ...extraParams,
      },
      title: 'Consumo do último mês',
      value: data.energy.consumption.total,
      ratio:
        data.energy.consumption.direction && data.energy.consumption.direction !== 'none'
          ? getRatio(data.energy.consumption)
          : undefined,
      variant: 'normal',
      redirectLink: CONSUMPTION_DASHBOARD_PATH,
    },
  ];
};

export const buildMigrationCards = (): Card[] => {
  const extraParams = {
    colors: {
      icon: 'text-primary-70',
      background: 'bg-primary-20',
    },
    solid: true,
  };

  return [
    {
      icon: {
        name: 'DocumentTextIcon',
        ...extraParams,
      },
      title: '',
      value: 'Seus Contratos',
      variant: 'minimal',
      redirectLink: CONTRACTS_PATH,
    },
    {
      icon: {
        name: 'ArrowsRightLeftIcon',
        ...extraParams,
      },
      title: '',
      value: 'Seu processo de migração',
      variant: 'minimal',
      redirectLink: MIGRATION_REPORT_PATH,
    },
  ];
};
