import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useUserInfo } from '@hooks/use-user-info';
import { QUERY_GET_UNITS_ECONOMY_REPORTS_BY_GROUP } from './queries';
import { UnitEconomyReport } from './types';

// TODO: get only required reports (filtering by year or getting only the last twelve reports)
export const useUnitsSimpleEconomyReports = () => {
  const { user, isFetchingUser } = useUserInfo();
  const [getUnitEconomyReports, { loading, data, error }] = useLazyQuery<{
    unitsEconomyReportsByGroupId: UnitEconomyReport[];
  }>(QUERY_GET_UNITS_ECONOMY_REPORTS_BY_GROUP, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (user?.group) {
      getUnitEconomyReports({
        variables: {
          id: user.group.id,
        },
      });
    }
  }, [user]);

  return {
    loading: loading || isFetchingUser,
    data: data?.unitsEconomyReportsByGroupId,
    error,
    dataHandler: () => data,
  };
};
