import { useState } from 'react';

import {
  ContractedDemandSimulationPayload,
  getAdjustedDemandSimulator as getAdjustedDemandSimulatorAdapter,
} from '@services/calculator';

export const useAdjustedDemandSimulator = () => {
  const [idealDemandPeak, setIdealDemandPeak] = useState<number | null>();
  const [idealDemandOffPeak, setIdealDemandOffPeak] = useState<number>();

  const getAdjustedDemandSimulator = async (params: ContractedDemandSimulationPayload) => {
    const response = await getAdjustedDemandSimulatorAdapter(params);

    if (response.success) {
      setIdealDemandPeak(response.data.newAdjustedDemandPeak);
      setIdealDemandOffPeak(response.data.newAdjustedDemandOffPeak);
    }
  };

  return {
    idealDemandPeak,
    idealDemandOffPeak,
    getAdjustedDemandSimulator,
  };
};
