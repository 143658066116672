import { useEffect, useMemo } from 'react';

import DailyConsumptionChart from '@components/organisms/consumption/daily-consumption-chart';
import HourConsumptionChart from '@components/organisms/consumption/hour-consumption-chart';
import DonutConsumptionByUnitChart from '@components/organisms/consumption/donut-consumption-by-unit-chart';

import { GroupDatetimeMeasurement } from '@contexts/consumption';

import { useConsumption } from '@hooks/use-consumption';
import { useUnitsConsumptionsByGroup } from '@hooks/consumption/use-units-consumptions-by-group';
import { useGetGroupAccumulatedEconomyReportByGroupId } from '@hooks/economy/get-group-accumulated-economy-report-by-group-id';
import { useUserInfo } from '@hooks/use-user-info';

import { parseMeasureDate } from '../helper';

import { calculateRelativePercent, getConsumptionsParamsByMonth } from './helpers';
import ConsumptionsCards from './consumptions-cards';

import style from './style.module.css';

const parseProjection = (value?: string | null) => {
  return value ? parseFloat(value) : null;
};

const MonthlyConsumptionByGroup = () => {
  const {
    monthlyGroupControlMeasurement,
    consumptionProjectionByGroup,
    monthYear,
    groupConsolidatedMeasurementById,
    setGroupConsolidatedMeasurementDocId,
  } = useConsumption();

  if (!monthYear) return null;

  const { consumptions: consumptionsByUnit } = useUnitsConsumptionsByGroup();

  const { user } = useUserInfo();

  const {
    getGroupAccumulatedEconomyReportByGroupId,
    parsedData: { rawSummaryEconomyReportsHistory },
  } = useGetGroupAccumulatedEconomyReportByGroupId();

  const isActualMonth = useMemo(() => {
    if (!monthYear) return false;

    const [month, year] = monthYear.split('/');
    const today = new Date();
    return today.getFullYear() === parseInt(year) && today.getMonth() + 1 === parseInt(month);
  }, [monthYear]);

  useEffect(() => {
    if (user?.group?.id) {
      getGroupAccumulatedEconomyReportByGroupId({
        variables: {
          groupId: user.group.id,
        },
      });
    }
  }, [user]);

  // This useEffect is necessary because the context does not complete loading before setGroupConsolidatedMeasurementDocId
  useEffect(() => {
    if (monthlyGroupControlMeasurement && !Object.keys(groupConsolidatedMeasurementById || {}).length) {
      if (!monthlyGroupControlMeasurement.length) return;

      setGroupConsolidatedMeasurementDocId(monthlyGroupControlMeasurement[0].groupConsolidatedMeasurementDocId);
    }
  }, []);

  return (
    <div className={style.container}>
      <div className={style.consumptionCards}>
        <ConsumptionsCards
          accumulatedConsumptionsParams={
            !isActualMonth ? getConsumptionsParamsByMonth(rawSummaryEconomyReportsHistory || [], monthYear) : undefined
          }
          projections={
            isActualMonth
              ? {
                  consumption: {
                    value: parseProjection(consumptionProjectionByGroup?.monthlyConsumptionProjection),
                    percent: consumptionProjectionByGroup?.monthlyConsumptionProjection
                      ? calculateRelativePercent({
                          monthYear,
                          groupAccumulatedEconomyReports: rawSummaryEconomyReportsHistory || [],
                          value: parseProjection(consumptionProjectionByGroup?.monthlyConsumptionProjection),
                          getValue: (economyReport) => economyReport.monthlyEnergyConsumption,
                        })
                      : null,
                  },
                }
              : undefined
          }
        />
      </div>
      <div className={style.dailyChart}>
        <DailyConsumptionChart
          title="Consumo por dia"
          exportName={'grafico-de-consumo-diario-mensal-' + monthYear.replace('/', '-')}
          measures={monthlyGroupControlMeasurement || []}
          projectedMeasures={consumptionProjectionByGroup?.dailyConsumptionProjection || []}
        />
      </div>
      <div className={style.hourCharts}>
        <HourConsumptionChart
          title="Consumo por hora"
          exportName="consumo-por-hora-do-grupo"
          days={monthlyGroupControlMeasurement?.map((measure) => parseMeasureDate(measure.date)) || []}
          defaultDay={parseMeasureDate(monthlyGroupControlMeasurement?.[0]?.date ?? '')}
          onChangeDay={(day) => {
            setGroupConsolidatedMeasurementDocId(
              monthlyGroupControlMeasurement?.find((measure) => parseMeasureDate(measure.date) === day)
                ?.groupConsolidatedMeasurementDocId,
            );
          }}
          consolidatedMeasures={
            groupConsolidatedMeasurementById?.datetimeMeasurement || ([] as GroupDatetimeMeasurement[])
          }
        />
      </div>
      <div className={style.donutChart}>
        <DonutConsumptionByUnitChart
          title="Consumo por UC"
          consumptions={Object.values(consumptionsByUnit || {})}
          monthYear={monthYear}
        />
      </div>
    </div>
  );
};

export default MonthlyConsumptionByGroup;
