import { BarChart } from '@clarke-energia/foton-v2';

import ChartContainer from '@components/molecules/charts/chart-container';

import { SimpleConsumption } from '@contexts/consumption';
import { consumptionsParse } from './parses';

interface GroupConsumptionHistoryBarChartProps {
  consumptions: SimpleConsumption[];
}
const GroupConsumptionHistoryBarChart = (props: GroupConsumptionHistoryBarChartProps) => {
  const data = consumptionsParse(props.consumptions, 'Consumo realizado');

  return (
    <ChartContainer
      title={{ text: 'Histórico de consumo' }}
      tooltipContent="Histórico de consumo mensal do Grupo comercial"
      export={{
        name: 'historico-de-consumo-mensal',
      }}
    >
      <BarChart
        labels={data.labels}
        series={data.series}
        options={{
          legend: { show: true },
          autoScale: true,
          suffix: ' MWh',
        }}
      />
    </ChartContainer>
  );
};

export default GroupConsumptionHistoryBarChart;
