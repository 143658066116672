import { gql } from '@apollo/client';

export const GET_USERS_QUERY = gql`
  query AllUsers {
    allUsers {
      id
      name
      email
      createdAt
      phone
    }
  }
`;

export const GET_MYSELF_QUERY = gql`
  query GetMyself {
    me {
      id
      name
      email
      trader {
        id
      }
    }
  }
`;

export const QUERY_GET_GROUP_BY_USER = gql`
  query getGroupByUser($id: ID!) {
    getGroupByUser(id: $id) {
      id
      group {
        id
        name
        type
        units {
          id
        }
      }
    }
  }
`;

export const QUERY_GET_GROUPS_BY_USER = gql`
  query getGroupsByUser($id: ID!) {
    getGroupsByUser(id: $id) {
      id
      group {
        id
        name
        type
        units {
          id
        }
      }
    }
  }
`;
