import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import ptbr from 'dayjs/locale/pt-br';

dayjs.extend(customParseFormat);

/**
 * PT-BR configured dayjs module instance.
 * */
dayjs.locale(ptbr);
export const $dayjs = dayjs;

export const parseStringToDate = (str: string): Date =>
  dayjs(str, ['YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD'], true).toDate();

export const formatDate = (date: Date, template = 'DD MMM YYYY') => dayjs(date).locale(ptbr).format(template);

export const formatDateFromString = (dateStr: string, inputFormat: string, outputFormat = 'DD/MM/YYYY, HH:mm') =>
  dayjs(dateStr, inputFormat, 'pt-br').format(outputFormat);

export const checkYearAfterNow = (dateStr: string) => {
  const dateNowYear = dayjs().year();
  const dateYear = dayjs(dateStr).year();

  return dateYear >= dateNowYear;
};

export const getOneYearLater = (dateStr: string, format = 'YYYY-MM-DD') => {
  const initialDate = dayjs(dateStr, format);
  const oneYearLater = initialDate.add(1, 'year');

  return oneYearLater.format(format);
};

export const formatDateToMonthNameAndYear = (date: string, fromFormat = 'YYYY-MM') => {
  const formattedDate = dayjs(date, fromFormat, 'pt-br').format('MMMM/YYYY');
  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
};

export const formatDateToAbbrMonthNameAndYear = (date: string) => {
  const formatted = dayjs(date).locale('pt-br').format('MMM/YYYY');
  return formatted.charAt(0).toUpperCase() + formatted.slice(1);
};

export const convertFromAbbrMonthNameAndYearToYearMonth = (dateStr: string) => {
  const dateFormatted = dateStr.charAt(0).toLowerCase() + dateStr.slice(1);
  const date = dayjs(dateFormatted, 'MMMM/YYYY', 'pt-br');
  return date.format('YYYY-MM');
};
