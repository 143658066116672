import { useEffect, useMemo } from 'react';

import { CardNumber } from '@clarke-energia/foton-v2';

import ConsumptionDemandChart from '@components/organisms/consumption/consumption-demand-chart';
import FinesWithReactivesChart from '@components/organisms/consumption/fines-with-reactives-chart';
import ConsumptionHistoryBarChart from '@components/organisms/consumption/consumption-history-bar-chart';

import { UnitEconomyReport } from '@hooks/economy/get-units-reports/types';
import { useAdjustedDemandSimulator } from '@hooks/consumption/use-adjusted-demand-simulator';
import { useGetUnitAverageConsumptions } from '@hooks/consumption/use-get-unit-average-consumptions';
import { ConsumptionsByUnit } from '@hooks/consumption/use-units-consumptions-by-group/types';
import { useGetAnnualUnitConsumptionProjection } from '@hooks/consumption/use-get-annual-unit-consumption-projection';

import { getDemandRequestData } from '../helper';

import { getUnitConsumptionHistory, getUnitConsumptionInformation } from './functions';
import style from './style.module.css';

interface AnnualConsumptionByUnitProps {
  unitId: string;
  consumptions?: ConsumptionsByUnit;
  economyReports?: UnitEconomyReport[];
}

const AnnualConsumptionByUnit = ({ unitId, consumptions, economyReports }: AnnualConsumptionByUnitProps) => {
  const { monthlyConsumption, getUnitAverageConsumptionsHandler } = useGetUnitAverageConsumptions();
  const { annualProjection, getUnitAnnualConsumptionProjectionHandler } = useGetAnnualUnitConsumptionProjection();
  const { getAdjustedDemandSimulator, idealDemandPeak, idealDemandOffPeak } = useAdjustedDemandSimulator();

  useEffect(() => {
    getUnitAverageConsumptionsHandler(unitId);
    getUnitAnnualConsumptionProjectionHandler(unitId);
  }, []);

  const unitReports =
    economyReports
      ?.filter((report) => {
        return report.unit.id === unitId;
      })
      .sort((a, b) => {
        return Date.parse(a.date) - Date.parse(b.date);
      }) ?? [];

  const unitConsumption = consumptions ? consumptions[unitId] : null;

  const unitConsumptionHistory = getUnitConsumptionHistory(unitConsumption ? unitConsumption.consumptions : []);

  const unitConsumptionInformation = getUnitConsumptionInformation({
    idealDemand: idealDemandPeak || idealDemandOffPeak || '-',
    projection: annualProjection ? parseFloat(annualProjection.toFixed(2)) : '-',
    average: monthlyConsumption ? parseFloat(monthlyConsumption.toFixed(2)) : '-',
  });

  const unitName = useMemo(() => {
    return unitConsumption ? unitConsumption.name : '-';
  }, [unitConsumption]);

  const unitDemand = useMemo(() => {
    if (!unitReports) {
      return {
        contractedDemand: 0,
        demands: [],
      };
    }
    const firstReport = unitReports.at(0);
    const contractedDemand = firstReport ? firstReport.unit.contractedDemandOffPeak : 0;

    return {
      contractedDemand: contractedDemand,
      demands: unitReports.map((el) => {
        return {
          date: el.date,
          value: el.freeMarket.readDemandOffPeak,
        };
      }),
    };
  }, [unitReports]);

  useEffect(() => {
    const demandRequestData = getDemandRequestData(unitReports.slice(-12));
    if (demandRequestData) {
      getAdjustedDemandSimulator(demandRequestData);
    }
  }, [unitReports]);

  return (
    <div className={style.container}>
      <div className={style.cardsContainer}>
        {unitConsumptionInformation.map((item) => {
          return <CardNumber key={item.title} {...item} className={style.innerCard} />;
        })}
      </div>
      <div className={style.historyChart}>
        <ConsumptionHistoryBarChart
          title={`Histórico de consumo - ${unitName}`}
          exportName={'historico-de-consumo-' + unitName.replace(' ', '-')}
          labels={unitConsumptionHistory.labels}
          values={unitConsumptionHistory.values}
        />
      </div>

      <div className={style.lowerChartsContainer}>
        <div className={style.lowerCharts}>
          <ConsumptionDemandChart
            title={`Demanda Real x Contratada - ${unitName}`}
            demands={unitDemand.demands}
            contractedDemand={unitDemand.contractedDemand}
          />
        </div>
        <div className={style.lowerCharts}>
          <FinesWithReactivesChart title={`Multas de reativos - ${unitName}`} reports={unitReports} />
        </div>
      </div>
    </div>
  );
};

export default AnnualConsumptionByUnit;
