import { useMemo } from 'react';

import { useLazyQuery } from '@apollo/client';

import { GetUnitAnnualConsumptionProjectionGraphQL } from './types';
import { GET_ANNUAL_CONSUMPTION_PROJECTION_BY_UNIT_ID } from './query';

export const useGetAnnualUnitConsumptionProjection = () => {
  const [getUnitAnnualConsumptionProjection, { loading, data }] =
    useLazyQuery<GetUnitAnnualConsumptionProjectionGraphQL>(GET_ANNUAL_CONSUMPTION_PROJECTION_BY_UNIT_ID, {
      fetchPolicy: 'network-only',
    });

  const getUnitAnnualConsumptionProjectionHandler = (unitId: string) => {
    getUnitAnnualConsumptionProjection({
      variables: {
        unitId: unitId,
      },
    });
  };

  const getAnnualConsumptionProjectionByUnitId = () => {
    if (!data) return 0;
    const { getAnnualConsumptionProjectionByUnitId } = data;
    return getAnnualConsumptionProjectionByUnitId.projectedConsumption;
  };

  const annualProjection = useMemo(() => getAnnualConsumptionProjectionByUnitId(), [data]);

  return {
    getUnitAnnualConsumptionProjectionHandler,
    annualProjection,
    loading,
  };
};
