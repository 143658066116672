import { $dayjs } from './dayjs';

export const getMonthsFromJanuaryToCurrent = (format = 'MM/YYYY') => {
  const dayjs = $dayjs;

  const current = dayjs();
  const january = current.month(0);
  let iterateOverMonths = january.clone();

  const diff = current.diff(january, 'month');

  const months: string[] = [];

  for (let i = 0; i <= diff; i++) {
    months.push(iterateOverMonths.format(format));
    iterateOverMonths = iterateOverMonths.add(1, 'month');
  }
  return months;
};

/**
 * @description Format date from 'YYYY-MM-DD' to 'MM/YYYY',  without data conversion
 * @param {string} date - Date in format 'YYYY-MM-DD...'
 */
export const formatMonthAndYearDate = (date: string) => {
  const [year, month] = date.split('-');
  return `${month}/${year}`;
};

/**
 * @description Sort an array of objects by month and year
 * @param {T[]} objs - Array of objects to be sorted
 * @param {Function} [getDateFunc] - Function to get the date from the object
 */
export const sortObjectsByMonthYear = <T>(objs: T[], getDateFunc?: (item: T) => string, overwrite = false): T[] => {
  const extractorDate = getDateFunc || ((item: T) => item as string);
  const objsToSort: T[] = overwrite ? objs : JSON.parse(JSON.stringify(objs));

  return objsToSort.sort((a, b) => {
    const [aMonth, aYear] = extractorDate(a).split('/');
    const [bMonth, bYear] = extractorDate(b).split('/');

    return `${aYear}${aMonth}`.localeCompare(`${bYear}${bMonth}`);
  });
};
