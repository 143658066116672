import { gql } from '@apollo/client';

export const GET_CONSOLIDATED_MEASUREMENT_BY_ID = gql`
  query GetConsolidatedMeasurementById($id: ID!) {
    getConsolidatedMeasurementById(id: $id) {
      datetimeMeasurement {
        datetime
        subType
        activeConsumption
      }
    }
  }
`;
