import React, { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { twMerge } from 'tailwind-merge';
import { BarChart, Modal, NuggetInfo } from '@clarke-energia/foton-v2';

import { Contract } from '@hooks/get-contracts/types';
import { useDownloadFiles } from '@hooks/download-files';
import { useIsMobile } from '@hooks/is-mobile';
import { ConsumptionReportGroupGraphQL, GroupConsumptionReport } from '@hooks/get-consumption-contracts/types';
import { buildConsumptionChart, buildPeriods } from '@hooks/get-consumption-contracts/parser';
import EnergyContractPrices from '@components/molecules/contract/energy-contrat-prices/prices';
import ChartContainer from '@components/molecules/charts/chart-container';
import { NuggetInfosAreaSkeleton } from '@components/molecules/skeleton/nugget-infos-area-skeleton';

import { checkYearAfterNow, getOneYearLater } from '@utils/dayjs';

import {
  energyNuggetInfo,
  managementNuggetInfo,
  ContentKeys,
  formatUnitsNames,
  READJUSTMENT_NUGGET_INFOS,
} from './helper';

import style from './style.module.css';

const handleChartData = (
  initialPeriod: string,
  finalPeriod: string,
  upperFlexibility: number,
  lowerFlexibility: number,
  consumptions: ConsumptionReportGroupGraphQL['getAllGroupsEconomyReportByGroupID'],
) => {
  const initialPeriodValue = checkYearAfterNow(initialPeriod)
    ? getOneYearLater(initialPeriod, 'MM/YYYY')
    : initialPeriod;
  const periods = buildPeriods(initialPeriodValue, finalPeriod);
  const data = buildConsumptionChart(consumptions, periods, upperFlexibility, lowerFlexibility);
  return data;
};

interface ConsumptionChartData {
  initialPeriod: string;
  finalPeriod: string;
  upperFlexibility: number;
  lowerFlexibility: number;
  consumptions: GroupConsumptionReport[];
}

export interface ContractModalProps {
  cardType: 'ENERGY' | 'MANAGEMENT';
  active: boolean;
  // chart?: ConsumptionChart;
  consumptionChartData?: ConsumptionChartData;
  content: Omit<Contract, 'type' | 'active'>;
  loadingChart: boolean;

  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ContractModal: React.FC<ContractModalProps> = ({
  cardType,
  active,
  isOpen,
  setIsOpen,
  content,
  consumptionChartData,
  loadingChart,
}) => {
  const isEnergyContract = cardType === 'ENERGY';
  const nuggetInfoData = isEnergyContract ? energyNuggetInfo : managementNuggetInfo;

  const isMobile = useIsMobile();
  // TODO: this information should come from the backend
  const isPercentagePrices = content.prices?.every(({ amount }) => amount.includes('%'));
  const downloadFiles = useDownloadFiles();

  const chartData = useMemo(() => {
    if (!consumptionChartData) return;

    return handleChartData(
      consumptionChartData.initialPeriod,
      consumptionChartData.finalPeriod,
      consumptionChartData.upperFlexibility,
      consumptionChartData.lowerFlexibility,
      consumptionChartData.consumptions,
    );
  }, [consumptionChartData]);

  return (
    <>
      <Modal
        className={style.modalContainer}
        isOpen={isOpen}
        setOpen={setIsOpen}
        title={{
          text: `Contrato de ${isEnergyContract ? 'energia' : 'gestão'} - ${formatUnitsNames(content.unitsNames)}`,
          extraClassName: style.modalTitle,
        }}
        buttonsTitle={
          content.file
            ? [
                {
                  icon: 'ArrowDownTrayIcon',
                  kind: 'primary',
                  size: 'small',
                  onClick: () => downloadFiles(content?.file?.link || '', content.file?.name || ''),
                },
              ]
            : []
        }
        tagTitle={{
          kind: 'icon',
          icon: { icon: 'CheckBadgeIcon', solid: false },
          label: `Contrato ${active ? 'ativo' : 'inativo'}`,
          className: style.tagTitle,
        }}
      >
        {loadingChart ? (
          <div className={style.skeleton}>
            <NuggetInfosAreaSkeleton />
            <div className={style.skeletonChart}>
              <Skeleton width="100%" height="100%" />
            </div>
          </div>
        ) : (
          <div className={twMerge(isEnergyContract && style.energyContractHeight, style.contentContainer)}>
            <div className={twMerge(style.content, isEnergyContract ? style.energyContractHeight : '')}>
              <div className={style.nuggetsContainer}>
                {Object.keys(nuggetInfoData).map((key) => {
                  const typedKey = key as ContentKeys;
                  const data = nuggetInfoData[typedKey];
                  const info = content[typedKey];

                  if (isPercentagePrices && READJUSTMENT_NUGGET_INFOS.includes(data.title)) return null;

                  return (
                    <div key={data.title}>
                      <NuggetInfo
                        title={data.title}
                        icon={{
                          icon: data.icon,
                          extraClassNames: 'bg-gray-10 rounded-[7px]',
                        }}
                        info={info ?? '-'}
                        sizes={{
                          info: isMobile ? 12 : 14,
                          title: isMobile ? 8 : 12,
                          icon: 16,
                          contentSpace: 4,
                        }}
                      />
                    </div>
                  );
                })}
              </div>

              <EnergyContractPrices
                prices={(content.prices || []).map(({ year, amount }) => ({
                  amount: amount.replace('R$', ''),
                  year,
                }))}
                visible={isEnergyContract}
              />

              {isEnergyContract && chartData && (
                <ChartContainer
                  title={{
                    text: 'Consumo Real x Consumo Contratado x Flex',
                    className: style.chartTitle,
                  }}
                  tooltipContent="Info grafico"
                  className={style.chartContainer}
                >
                  <BarChart
                    title=""
                    series={chartData.series}
                    labels={chartData.labels}
                    lines={chartData.lines}
                    dashedSeries={[]}
                    options={{
                      legend: { show: true, bottom: 0 },
                      leftSpace: 65,
                      rightSpace: 30,
                      suffix: 'MWh',
                    }}
                  />
                </ChartContainer>
              )}
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default ContractModal;
