import { gql } from '@apollo/client';

export const GET_UNIT_AVERAGE_CONSUMPTIONS = gql`
  query GetUnitAverageConsumption($unitId: ID!) {
    getUnitAverageConsumption(unitId: $unitId) {
      unitId

      daily
      monthly
      yearly
    }
  }
`;
