export class GreenColorPicker {
  colors: string[] = [
    '#50C878',
    '#00FF7F',
    '#008000',
    '#90EE90',
    '#7FFFD4',
    '#097969',
    '#AFE1AF',
    '#023020',
    '#7CFC00',
    '#454B1B',
    '#0FFF50'
  ];
  current: number = 0;

  public pickOne() {
    const color = this.colors[this.current];
    this.current += 1;
    if (this.current >= this.colors.length) {
      this.current = 0;
    }
    return color;
  }
}
