import React from 'react';

import { BarChart, BarChartProps } from '@clarke-energia/foton-v2';

import { formatMonthAndYearDate } from '@utils/date';
import ChartContainer from '@components/molecules/charts/chart-container';

const handleChartData = (
  data: ConsumptionDemandChartProps['demands'],
  contractedDemand: number,
): Omit<BarChartProps, 'lines' | 'options' | 'title'> => {
  const lastYearData = data.slice(-12);
  const labels = lastYearData.map((demand) => formatMonthAndYearDate(demand.date));
  const series: BarChartProps['series'] = [];

  series.push({
    name: 'Demanda Real',
    color: '#96E9B6',
    data: lastYearData.map((demand) => demand.value),
  });
  series.push({
    name: 'Demanda Contratada',
    color: '#80ACFF',
    data: lastYearData.map(() => contractedDemand),
  });

  return {
    labels,
    series,
  };
};

export interface ConsumptionDemandChartProps {
  title: string;
  contractedDemand: number;
  demands: {
    date: string;
    value: number;
  }[];
}

const ConsumptionDemandChart: React.FC<ConsumptionDemandChartProps> = ({ title, ...props }) => {
  const { labels, series } = handleChartData(props.demands, props.contractedDemand);

  return (
    <ChartContainer
      title={{
        text: title,
      }}
      className=""
      tooltipContent="Comparação entre a demanda real x a demanda contratada."
    >
      <BarChart
        labels={labels}
        series={series}
        lines={props.contractedDemand ? [{ name: 'Limite', color: '#FFBC14', y: props.contractedDemand * 1.05 }] : []}
        options={{
          legend: { show: true, bottom: 0 },
          autoScale: true,
          suffix: ' kW',
        }}
      />
    </ChartContainer>
  );
};

export default ConsumptionDemandChart;
