import { CardNumberProps } from '@clarke-energia/foton-v2';

import { ITotalMonthlyConsumption } from '@contexts/consumption';

import { ChartData } from '../helper';

interface UnitConsumptionInformation {
  average: number | string;
  projection: number | string;
  idealDemand: number | string;
}

export const getUnitConsumptionHistory = (consumptions: ITotalMonthlyConsumption[]) => {
  const historyConsumption: ChartData = consumptions.reduce(
    (prev: ChartData, cur) => {
      const history = { ...prev };
      history.labels.push(cur.referenceMonthYear);
      history.values.push(cur.consumption);
      return history;
    },
    {
      labels: [],
      values: [],
    },
  );

  return historyConsumption;
};

export const getUnitConsumptionInformation = ({
  average,
  idealDemand,
  projection,
}: UnitConsumptionInformation): CardNumberProps[] => {
  return [
    {
      icon: {
        name: 'LightBulbIcon',
        size: 'lg',
      },
      title: 'Previsão de consumo anual',
      value: `${projection} MWh`,
      variant: 'compact',
    },
    {
      icon: {
        name: 'CalendarDaysIcon',
        size: 'lg',
      },
      title: 'Consumo médio mensal',
      value: `${average} MWh`,
      variant: 'compact',
    },
    {
      icon: {
        name: 'ShieldCheckIcon',
        size: 'lg',
      },
      title: 'Demanda ideal',
      value: `${idealDemand} kW`,
      variant: 'compact',
      subtitle: 'com base no histórico dos últimos 12 meses',
    },
  ];
};
