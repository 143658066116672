import { gql } from '@apollo/client';

export const GET_UNIT_DAILY_CONSUMPTION_PROJECTION_BY_UNIT_ID_AND_MONTH_YEAR = gql`
  query GetMonthlyConsumptionProjectionByUnitId($input: MonthlyConsumptionProjectionByUnitId!) {
    getMonthlyConsumptionProjectionByUnitId(input: $input) {
      unitId
      monthYear

      dailyConsumptionProjection {
        date
        consumptionPredicted
        consumptionPredictedConstrained
      }
    }
  }
`;
