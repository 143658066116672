import { gql } from '@apollo/client';

export const GET_MONTHLY_CONTROL_MEASUREMENT_BY_UNIT_QUERY = gql`
  query getMonthlyControlMeasurementByUnitId($input: GetControlMeasurementInput!) {
    getMonthlyControlMeasurementByUnitId(input: $input) {
      id
      groupId
      unitId
      consolidatedMeasurementDocId
      monthYear
      date
      totalMissingHours
      totalConsumption
      createdAt
    }
  }
`;

export const GET_CONSOLIDATED_MEASUREMENT_BY_ID_QUERY = gql`
  query GetConsolidatedMeasurementById($getConsolidatedMeasurementByIdId: ID!) {
    getConsolidatedMeasurementById(id: $getConsolidatedMeasurementByIdId) {
      id
      profileCcee
      measuringPoint
      weekday
      monthYear
      datetimeMeasurement {
        datetime
        subType
        status
        activeGeneration
        reactiveGeneration
        activeConsumption
        reactiveConsumption
        source
      }
      createdAt
    }
  }
`;

export const GET_UNIT_CONSUMPTION_MEASURES_PER_HOUR_QUERY = gql`
  query GetUnitConsumptionMeasuresPerHour($input: GetMonthlyConsumptionMeasuresPerUnitInput!) {
    getUnitConsumptionMeasuresPerHour(input: $input) {
      id
      datetimeMeasurement {
        datetime
        subType
        status
        activeConsumption
        source
      }
    }
  }
`;

export const GET_UNITS_QUERY = gql`
  query Group($groupId: ID!) {
    group(id: $groupId) {
      units {
        id
        name
      }
    }
  }
`;

export const GET_MEASURING_POINT_BY_GROUP_ID_QUERY = gql`
  query GetMeasuringPointByGroupId($groupId: ID!) {
    getMeasuringPointByGroupId(groupId: $groupId) {
      unitId
    }
  }
`;

export const GET_CONSUMPTION_PROJECTION_BY_UNIT_ID_QUERY = gql`
  query GetMonthlyConsumptionProjectionByUnitId($input: MonthlyConsumptionProjectionByUnitId!) {
    getMonthlyConsumptionProjectionByUnitId(input: $input) {
      id
      unitId
      monthYear
      score
      maxLimit
      minLimit
      monthlyConsumptionProjection
      dailyConsumptionProjection {
        date
        consumptionPredicted
        consumptionPredictedConstrained
      }
    }
  }
`;

export const GET_ALL_TOTAL_MONTHLY_CONSUMPTION_BY_UNIT_ID_QUERY = gql`
  query GetAllTotalConsumptionMeasuresPerUnit($unitId: ID!) {
    getAllTotalConsumptionMeasuresPerUnit(unitId: $unitId) {
      referenceMonthYear
    }
  }
`;

export const GET_MONTHLY_GROUP_CONTROL_MEASUREMENTS_QUERY = gql`
  query GetMonthlyControlMeasurementByGroupId($input: GetMonthlyControlMeasurementByGroupIdInput!) {
    getMonthlyControlMeasurementByGroupId(input: $input) {
      id
      groupId
      groupConsolidatedMeasurementDocId
      date
      monthYear
      totalConsumption
      totalMissingHours
    }
  }
`;

export const GET_GROUP_CONSOLIDATED_MEASUREMENTS_BY_ID_QUERY = gql`
  query GetGroupConsolidatedMeasurementById($id: ID!) {
    getGroupConsolidatedMeasurementById(id: $id) {
      id
      groupId
      date
      weekday
      monthYear
      datetimeMeasurement {
        profileCcee
        datetime
        activeConsumption
      }
    }
  }
`;

export const GET_GROUP_CONSUMPTION_PROJECTION_QUERY = gql`
  query GetMonthlyGroupConsumptionProjection($input: MonthlyGroupConsumptionProjectionInput!) {
    getMonthlyGroupConsumptionProjection(input: $input) {
      id
      groupId
      monthYear
      monthlyConsumptionProjection
      dailyConsumptionProjection {
        date
        consumptionPredictedConstrained
        consumptionPredicted
      }
    }
  }
`;

export const GET_ALL_TOTAL_MONTHLY_CONSUMPTION_BY_GROUP_ID_QUERY = gql`
  query GetAllTotalConsumptionMeasuresPerGroupByMonthYear($groupId: ID!) {
    getAllTotalConsumptionMeasuresPerGroupByMonthYear(groupId: $groupId) {
      referenceMonthYear
      consumption
    }
  }
`;

export const GET_ECONOMY_REPORT_BY_GROUP_AND_DATE_QUERY = gql`
  query UnitsEconomyReportsByGroupIdAndDate($groupId: ID!, $date: String!) {
    unitsEconomyReportsByGroupIdAndDate(groupId: $groupId, date: $date) {
      groupId
      date
      unit {
        id
      }
      freeMarket {
        totalValue
      }
    }
  }
`;
