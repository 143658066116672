import { gql } from '@apollo/client';

export const GET_MIGRATION_REPORT_BY_GROUP_ID = gql`
  query GetMigrationReportByGroupId($groupId: ID!) {
    getMigrationReportByGroupId(groupId: $groupId) {
      id
      conclusionPercentage
    }
  }
`;
