import React from 'react';

import Skeleton from 'react-loading-skeleton';
import BoltIcon from '@assets/green-bolt.svg';
import MobileBoltIcon from '@assets/mobile-green-bolt.svg';

import Icon from './icon';
import ChartGauge, { ChartProps } from './chart-gauge';

import style from './style.module.css';

interface SectionTextIconProps {
  loading: boolean;
  chart: ChartProps;
  text: React.ReactNode;
  isMigration?: boolean;
}

const SectionTextIcon: React.FC<SectionTextIconProps> = ({ text, loading, chart, isMigration }) => {
  return (
    <div className={style.textIconContainer}>
      {loading ? (
        <Skeleton height={200} className="z-10" />
      ) : (
        <div className="z-10">
          <p className={style.title}>Boas vindas à área do cliente!</p>
          <div className={style.text}>{text}</div>
        </div>
      )}
      <ChartGauge {...chart} />
      <Icon src={BoltIcon} className={style.boltIcon} />
      <Icon src={MobileBoltIcon} className={isMigration ? style.migrationBoltIconMobile : style.boltIconMobile} />
    </div>
  );
};

export default SectionTextIcon;
