import React from 'react';
import { twMerge } from 'tailwind-merge';
import { useLocation } from 'react-router-dom';
import { Header, HeaderProps } from '@clarke-energia/foton-v2';

import Sidebar, { SidebarProps } from '@components/molecules/sidebar';
import SidebarMobile from '@components/molecules/menu-sidebar-mobile';

import { HOME_PATH } from '@routers/constants';
import { useIsMobile } from '@hooks/is-mobile';

import style from './style.module.css';

interface HeaderLayoutProps extends HeaderProps {
  displayDesktop: boolean;
  additionalButtons?: React.ReactNode;
}

interface LayoutContentProps {
  header?: HeaderLayoutProps;
  children: React.ReactNode;
  extraContentClassName?: string;
  dashboardStyle?: {
    children: React.ReactNode;
  };
}

interface LayoutRootProps {
  children: React.ReactNode;
}

const SideBarContainer = () => {
  const { pathname } = useLocation();

  const sideBarStyle: SidebarProps = {
    menuColor: 'default',
    showCollapsedButton: true,
  };

  if (pathname === HOME_PATH) {
    sideBarStyle.collapsed = true;
    sideBarStyle.menuColor = 'green';
    sideBarStyle.showCollapsedButton = false;
  }

  return <Sidebar {...sideBarStyle} />;
};
const LayoutRoot: React.FC<LayoutRootProps> = (props: LayoutRootProps) => {
  const { pathname } = useLocation();

  return (
    <div className={twMerge(style.container, pathname === HOME_PATH ? 'bg-greenGradient relative' : '')}>
      <SideBarContainer />
      {props.children}
    </div>
  );
};

const LayoutContent: React.FC<LayoutContentProps> = (props: LayoutContentProps) => {
  const isMobile = useIsMobile();

  const { displayDesktop, additionalButtons, ...headerProps } = props.header ?? {
    title: '',
    displayDesktop: false,
    additionalButtons: null,
  };

  return (
    <div className={style.mainContainer}>
      {!props.dashboardStyle && headerProps && (
        <Header
          {...headerProps}
          displayLogo={isMobile}
          breadCrumbLinks={isMobile ? [] : headerProps.breadCrumbLinks}
          className={twMerge(
            headerProps.className,
            style.headerPadding,
            displayDesktop ? '' : 'md:hidden',
            style.header,
          )}
          breadCrumbColors={{
            current: 'text-[#222222]',
            previous: 'text-[#9C9C9B]',
          }}
        >
          <div className={style.headerItemsContainer}>
            {headerProps.children}
            <div className={style.buttonsContainer}>
              {additionalButtons}
              <SidebarMobile />
            </div>
          </div>
        </Header>
      )}
      {props.dashboardStyle && (
        <div className={style.dashboardHeader}>
          {props.dashboardStyle.children}
          <div className={style.buttonsContainer}>
            {additionalButtons}
            <SidebarMobile />
          </div>
        </div>
      )}

      <main
        className={twMerge(
          style.contentContainer,
          props.extraContentClassName,
          props.dashboardStyle?.children ? '' : 'bg-[#f2f2f2]',
        )}
      >
        {props.children}
      </main>
    </div>
  );
};

export default {
  Root: LayoutRoot,
  Content: LayoutContent,
};
