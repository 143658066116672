import { UnitEconomyReport } from './types';

export const unifyReportsByUnit = (reports: UnitEconomyReport[]) => {
  return reports.reduce((acc, report) => {
    const unitId = report.unit.id;
    if (!acc[unitId]) {
      acc[unitId] = [];
    }
    acc[unitId].push(report);
    return acc;
  }, {} as Record<string, UnitEconomyReport[]>);
};
