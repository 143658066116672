import { useLazyQuery } from '@apollo/client';

import { Unit } from './types';
import { GET_GROUP_QUERY } from './queries';

export const useGetGroup = () => {
  const [getGroup, { data, loading, error }] = useLazyQuery<{
    group: {
      id: string;
      units: Unit[];
    };
  }>(GET_GROUP_QUERY, {
    fetchPolicy: 'network-only',
  });

  function getGroupHandler(groupId: string) {
    return getGroup({ variables: { groupId } });
  }

  return {
    data,
    loading,
    error,
    getGroup: getGroupHandler,
  };
};
