import { LineChart } from '@clarke-energia/foton-v2';
import ChartContainer from '@components/molecules/charts/chart-container';
import { UnitEconomyReport } from '@hooks/economy/get-units-reports/types';
import { ChartData } from '@pages/consumption/hub/helper';
import { formatMonthAndYearDate } from '@utils/date';

interface FinesWithReactivesChartProps {
  title: string;
  reports: UnitEconomyReport[];
}

const getReactivesFromReportForChart = (reports: UnitEconomyReport[]) => {
  return reports.reduce(
    (prev: ChartData, cur) => {
      prev.labels.push(formatMonthAndYearDate(cur.date));
      prev.values.push(cur.freeMarket.energyDistribution.reactivesTotal);
      return prev;
    },
    {
      labels: [],
      values: [],
    },
  );
};

const FinesWithReactivesChart: React.FC<FinesWithReactivesChartProps> = ({
  title,
  reports,
}: FinesWithReactivesChartProps) => {
  const data = getReactivesFromReportForChart(reports);

  return (
    <ChartContainer
      title={{
        text: title,
      }}
      className=""
      tooltipContent="Multas incididas em reativos"
    >
      <LineChart
        labels={data.labels}
        series={[
          {
            name: 'Multas de reativos',
            values: data.values,
            color: '#00DF7C',
          },
        ]}
        lines={[]}
        options={{
          legend: { show: true },
          autoScale: true,
          prefix: 'R$ ',
        }}
      />
    </ChartContainer>
  );
};

export default FinesWithReactivesChart;
