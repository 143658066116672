import { gql } from '@apollo/client';

export const GET_LAST_GROUP_ECONOMY_REPORT = gql`
  query GetLastEconomyReportByGroupId($groupId: ID!) {
    lastGroupEconomyReportByGroupId(groupId: $groupId) {
      date
      totalMonthlyEnergyConsumption
      totalMonthlyEnergyCost
      totalMonthlySavings
      totalMonthlySavingsPercent
      energyCostBalancePercent
      energyConsumptionBalancePercent
      savingBalancePercent
      economyReports {
        freeMarket {
          totalValue
        }
        conventionalMarket {
          totalValue
        }
      }
    }
  }
`;

export const GET_LAST_UNIT_ECONOMY_REPORT = gql`
  query GetLastUnitReport($unitId: ID!) {
    lastEconomyReportByUnitId(unitId: $unitId) {
      date
      energyConsumptionBalancePercent
      energyTotalConsumption
      energyCostBalancePercent
      energyTotalCost
      savingBalancePercent
      currentSavings
      savingsPercent
      freeMarket {
        totalValue
      }
      conventionalMarket {
        totalValue
      }
    }
  }
`;

export const GET_LAST_ACCUMULATED_ECONOMY_REPORT = gql`
  query GetLastAccumulatedEconomyReportByGroupId($groupId: ID!) {
    getLastAccumulatedEconomyReportByGroupId(groupId: $groupId) {
      summaryEconomyReportsHistory {
        date
        monthlyEnergyCost
        monthlySavingsPercentage
      }
    }
  }
`;
