import React, { useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { Card, TooltipComponent } from '@clarke-energia/foton-v2';

import ExportSelect from '@components/molecules/export-select/default';
import ExportSelectIcon from '@components/molecules/export-select/icon';
import { useExportElementAsImage } from '@hooks/export-as-image';
import { useDownloadFiles } from '@hooks/download-files';
import { useIsMobile } from '@hooks/is-mobile';
import { useAuthorizationHeaders } from '@hooks/use-authorization-headers';
import { DEFAULT_CLASS_HIDDEN_ON_EXPORT } from '@hooks/export-as-image/constants';

import style from './style.module.css';

export interface ChartContainerProps {
  children: React.ReactNode;
  title: {
    text: string;
    className?: string;
  };
  className?: string;
  tooltipContent?: string;
  extraHeaderComponent?: React.ReactNode;
  export?: {
    name: string;
    excelPath?: string;
  };
}

const ChartContainer: React.FC<ChartContainerProps> = (props) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const exportAsImage = useExportElementAsImage();
  const downloadExcel = useDownloadFiles();
  const headersWithAuthorization = useAuthorizationHeaders();
  const isMobile = useIsMobile();

  const ExportSelectComponent = isMobile || props.extraHeaderComponent ? ExportSelectIcon : ExportSelect;

  return (
    <div ref={chartRef} className="w-full h-full">
      <Card.Root className={twMerge('h-full w-full', props.className || '')}>
        <Card.Header>
          <div className="flex w-full">
            <p className={twMerge(props.export || props.extraHeaderComponent ? 'max-w-[80%]' : '')}>
              <span className={twMerge(style.title, props.title.className)}>{props.title.text}</span>

              {props.tooltipContent && (
                <span className={DEFAULT_CLASS_HIDDEN_ON_EXPORT}>
                  <span className={style.tooltip}>
                    <TooltipComponent
                      icon={{ icon: 'InformationCircleIcon' }}
                      content={props.tooltipContent}
                      side="top"
                    />
                  </span>
                </span>
              )}
            </p>

            <div className="grow hidden-on-export">{props.extraHeaderComponent}</div>
            {props.export && (
              <span className={twMerge('pl-4 ml-auto hidden-on-export', DEFAULT_CLASS_HIDDEN_ON_EXPORT)}>
                <ExportSelectComponent
                  availableExports={{
                    png: true,
                    excel: false,
                  }}
                  onChange={(exportMediaType) => {
                    if (!props.export || !chartRef.current) return;

                    if (exportMediaType == 'PNG') {
                      exportAsImage({
                        element: chartRef?.current,
                        exportName: props.export.name,
                        classToHide: 'hidden-on-export',
                      });
                    } else if (exportMediaType == 'EXCEL') {
                      downloadExcel(props.export?.excelPath || '', props.export.name, headersWithAuthorization);
                    }
                  }}
                  placeholder={props.extraHeaderComponent ? null : undefined}
                  exportIcon="ArrowDownTrayIcon"
                  bordered
                />
              </span>
            )}
          </div>
        </Card.Header>
        {props.children}
      </Card.Root>
    </div>
  );
};

export default ChartContainer;
