import { QueryResult } from '@apollo/client';
import { IconProps } from '@clarke-energia/foton';

export type ConsumptionContextType = {
  getMonthlyControlMeasurementByUnitIdHandler: () => Promise<
    QueryResult<
      MonthlyControlMeasurementByUnitId,
      {
        input: {
          unitId: string;
          monthYear: string;
        };
      }
    >
  >;
  setConsolidatedMeasurementDocId: React.Dispatch<React.SetStateAction<string | undefined>>;
  setGroupId: React.Dispatch<React.SetStateAction<string | undefined>>;
  setUnitId: React.Dispatch<React.SetStateAction<string | undefined>>;
  unitId: string | undefined;
  units: Array<Units> | undefined;
  setMonthYear: React.Dispatch<React.SetStateAction<string | undefined>>;
  monthYear: string | undefined;
  setMonthlyControlMeasurementByUnitId: React.Dispatch<
    React.SetStateAction<Array<MonthlyControlMeasurementByUnitId> | undefined>
  >;
  monthlyControlMeasurementByUnitId: Array<MonthlyControlMeasurementByUnitId> | undefined;
  unitConsumptionMeasuresPerHour: Array<UnitConsumptionMeasuresPerHour> | undefined;
  consolidatedMeasurementById: ConsolidatedMeasurement | undefined;
  openNotificationFeedBack: boolean;
  setOpenNotificationFeedBack: (value: boolean) => void;
  openNotificationFeedBackSync: boolean;
  setOpenNotificationFeedBackSync: (value: boolean) => void;
  consumptionProjectionByUnit: IConsumptionProjection | undefined;
  totalMonthlyConsumptionByUnit: ITotalMonthlyConsumption[] | undefined;
  totalMonthlyConsumptionByGroup: ITotalMonthlyConsumption[] | undefined;
  monthlyGroupControlMeasurement: Array<MonthlyGroupControlMeasurement> | undefined;
  setMonthlyGroupControlMeasurement: React.Dispatch<
    React.SetStateAction<Array<MonthlyGroupControlMeasurement> | undefined>
  >;
  setGroupConsolidatedMeasurementDocId: React.Dispatch<React.SetStateAction<string | undefined>>;
  groupConsolidatedMeasurementById: GroupConsolidatedMeasurement | undefined;
  consumptionProjectionByGroup: IGroupConsumptionProjection | undefined;
  invoicePreviewOfUnit: number | undefined;
  invoicePreviewOfGroup: number | undefined;
  pastInvoiceGroup: number | undefined;
  pastInvoiceUnit: number | undefined;
  loading: boolean;
};

export interface ConsolidatedMeasurementByIdGraphQLData {
  getConsolidatedMeasurementById: ConsolidatedMeasurement;
}

export interface GroupConsolidatedMeasurementByIdGraphQLData {
  getGroupConsolidatedMeasurementById: GroupConsolidatedMeasurement;
}

export interface MonthlyControlMeasurementByUnitIdGraphQLData {
  getMonthlyControlMeasurementByUnitId: Array<MonthlyControlMeasurementByUnitId>;
}

export interface MonthlyGroupControlMeasurementGraphQLData {
  getMonthlyControlMeasurementByGroupId: Array<MonthlyGroupControlMeasurement>;
}

export interface UnitConsumptionMeasuresPerHourGraphQLData {
  getUnitConsumptionMeasuresPerHour: Array<UnitConsumptionMeasuresPerHour>;
}
export interface MonthlyControlMeasurementByUnitId {
  id: string;
  groupId: string;
  unitId: string;
  consolidatedMeasurementDocId: string;
  monthYear: string;
  totalMissingHours: number;
  totalConsumption: number;
  date: string;
  createdAt?: string;
}

export interface MonthlyGroupControlMeasurement {
  id: string;
  groupId: string;
  groupConsolidatedMeasurementDocId: string;
  date: string;
  monthYear: string;
  totalMissingHours: number;
  totalConsumption: number;
  createdAt?: string;
}
export interface ConsolidatedMeasurement {
  id: string;
  profileCcee: string;
  measuringPoint: string;
  weekday: number;
  monthYear: string;
  datetimeMeasurement: Array<DatetimeMeasurement>;
  createdAt: string;
}

export interface GroupDatetimeMeasurement {
  profileCcee: string;
  datetime: string;
  activeConsumption: string;
}
export interface GroupConsolidatedMeasurement {
  id: string;
  groupId: string;
  date: string;
  weekday: number;
  monthYear: string;
  datetimeMeasurement: Array<GroupDatetimeMeasurement>;
}

export interface UnitConsumptionMeasuresPerHour {
  id: string;
  datetimeMeasurement: Array<DatetimeMeasurement>;
}

export interface DatetimeMeasurement {
  datetime: string;
  subType: string;
  status: DatetimeMeasurementStatus;
  activeGeneration: string;
  reactiveGeneration: string;
  activeConsumption: string;
  reactiveConsumption: string;
  source: Source;
}

export enum Source {
  ccee = 'ccee',
  clarke = 'clarke',
}

export enum DatetimeMeasurementStatus {
  HCC = 'HCC',
  HCI = 'HCI',
  HEC = 'HEC',
  HEI = 'HEI',
  HIF = 'HIF',
  HE = 'HE',
  HEE = 'HEE',
  HEA = 'HEA',
  HR = 'HR',
}

export type IconColor = 'primary' | 'neutral';
export interface ConsumptionIconCardStaticProps {
  title: string;
  label: string;
  icon: IconProps['icon'];
  iconColor: IconColor | undefined;
  tooltip?: { content: string } | undefined;
  labelSize?: string;
}

export interface Units {
  id: string;
  name: string;
}

export interface Group {
  units: Units[];
}

export interface UnitsGraphQLData {
  group: Group;
}

export interface UnitsIdWithMeasuringPoint {
  unitId: string;
}

export interface AllMeasuringPointByGroupGraphQLData {
  getMeasuringPointByGroupId: Array<UnitsIdWithMeasuringPoint>;
}

export interface IDateProjection {
  date: string;
  consumptionPredicted: string | number;
  consumptionPredictedConstrained: string | number;
}
export interface IConsumptionProjection {
  id: string;
  unitId: string;
  monthYear: string;
  score: Array<number>;
  maxLimit: Array<number>;
  minLimit: Array<number>;
  monthlyConsumptionProjection: number;
  dailyConsumptionProjection: IDateProjection[];
}
export interface ConsumptionProjectionByUnitIdGraphQLData {
  getMonthlyConsumptionProjectionByUnitId: IConsumptionProjection;
}

export interface IGroupConsumptionProjection {
  id: string;
  groupId: string;
  monthYear: string;
  monthlyConsumptionProjection: string;
  dailyConsumptionProjection: IDateProjection[];
}

export interface ConsumptionProjectionByGroupIdGraphQLData {
  getMonthlyGroupConsumptionProjection: IGroupConsumptionProjection;
}

export interface SimpleConsumption {
  consumption: number;
  referenceMonthYear: string;
}

export interface ITotalMonthlyConsumption extends SimpleConsumption {
  /* TODO: this interface is use to group too */
  unitId: string;
  referenceMonthYear: string;
  consumption: number;
  averageConsumption: number;
}
export interface AllTotalConsumptionByUnitIdGraphQLData {
  getAllTotalConsumptionMeasuresPerUnit: ITotalMonthlyConsumption[];
}

export interface AllTotalConsumptionByGroupIdGraphQLData {
  getAllTotalConsumptionMeasuresPerGroupByMonthYear: ITotalMonthlyConsumption[];
}

export interface Invoice {
  date: string;
  unit: { id: string };
  freeMarket: { totalValue: number };
  groupId: string;
}
export interface GetEconomyReportByGroupAndDateGraphQLData {
  unitsEconomyReportsByGroupIdAndDate: Invoice[];
}
