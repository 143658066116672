import { useQuery } from '@apollo/client';

import { GET_ALL_GROUPS } from './queries';
import { CommercialGroupGraphQLData } from './types';

export const useGetGroups = () => {
  const { data, loading, error } = useQuery<CommercialGroupGraphQLData>(GET_ALL_GROUPS, {
    fetchPolicy: 'network-only',
  });

  return {
    data,
    loading,
    error,
  };
};
