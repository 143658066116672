import { BarChart } from '@clarke-energia/foton-v2';

import ChartContainer from '@components/molecules/charts/chart-container';
import { IDateProjection } from '@contexts/consumption';

import { MonthlyControlMeasurement } from '@hooks/consumption/use-get-unit-control-measurements-by-unit-id-and-month-year/types';

const parseChartData = (measures: MonthlyControlMeasurement[], projectedMeasures: IDateProjection[]) => {
  const labels = measures.map((measure) => measure.date.split('-')[2]);
  const data: number[] = measures.map((measure) => measure.totalConsumption);

  const series = [
    {
      data,
      name: 'Consumo realizado',
      color: '#00BF6A',
    },
  ];

  if (projectedMeasures.length) {
    const complementMeasures = projectedMeasures.filter(
      (projectedMeasure) => !labels.includes(projectedMeasure.date.split('-')[2]),
    );
    labels.push(...complementMeasures.map((projectedMeasure) => projectedMeasure.date.split('-')[2]));
    let complementData = complementMeasures.map((projectedMeasure) =>
      typeof projectedMeasure.consumptionPredicted == 'string'
        ? parseFloat(projectedMeasure.consumptionPredicted)
        : projectedMeasure.consumptionPredicted,
    );
    if (complementData.length < data.length) {
      complementData = Array(data.length - complementData.length)
        .fill(0)
        .concat(complementData);
    }
    series.push({
      data: complementData,
      name: 'Consumo Projetado',
      color: '#CFDFFC',
    });

    if (series[0].data.length !== series[1].data.length) {
      series[0].data.push(...Array(series[1].data.length - series[0].data.length).fill(0));
      series[1].data.push(...Array(series[0].data.length - series[1].data.length).fill(0));
    }
  }

  return {
    labels,
    series,
  };
};

interface DailyConsumptionChartProps {
  title: string;
  exportName: string;
  measures: MonthlyControlMeasurement[];
  projectedMeasures?: IDateProjection[];
}

const DailyConsumptionChart = ({ title, measures, projectedMeasures, exportName }: DailyConsumptionChartProps) => {
  const { labels, series } = parseChartData(measures, projectedMeasures || []);

  return (
    <ChartContainer
      title={{ text: title }}
      className=""
      tooltipContent="Esse gráfico mostra o consumo realizado e projetado diário"
      export={{
        name: exportName,
      }}
    >
      <BarChart
        labels={labels}
        stack
        options={{
          legend: { show: true },
          autoScale: true,
          suffix: ' MWh',
        }}
        series={series}
      />
    </ChartContainer>
  );
};

export default DailyConsumptionChart;
