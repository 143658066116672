import { gql } from '@apollo/client';

export const GET_UNIT_CONTROL_MEASUREMENTS_BY_UNIT_ID_AND_MONTH_YEAR = gql`
  query GetMonthlyControlMeasurementByUnitId($input: GetControlMeasurementInput!) {
    getMonthlyControlMeasurementByUnitId(input: $input) {
      date
      unitId
      monthYear
      totalConsumption
      consolidatedMeasurementDocId
    }
  }
`;
