import axios from 'axios';

import config from '@src/config';
import { UnitTypeEnum } from '@hooks/get-group/types';

interface ApiResponse<T> {
  success: boolean;
  message: string | null;
  code: string | null;
  data: T;
}

interface AdjustedDemandData {
  newAdjustedDemandOffPeak: number;
  annualEconomyOffPeak: number;
  newAdjustedDemandPeak: number | null;
  annualEconomyPeak: number | null;
  clarkeFee: number;
  annualClarkeValue: number;
  isWorthwhile: boolean;
}

export interface ContractedDemandSimulationPayload {
  contractedDemandOffPeak: number | null;
  contractedDemandPeak: number | null;
  energyDistributionCompany: string;
  icmsType: UnitTypeEnum;
  measuredDemandOffPeak: number[] | undefined;
  tariff: number;
  measuredDemandPeak: number[] | undefined;
}

export const getAdjustedDemandSimulator = async (params: ContractedDemandSimulationPayload) => {
  const calculatorApiClient = axios.create({
    baseURL: config.CALCULATOR_API_HOST,
    headers: {
      Authorization: `Apikey ${config.CALCULATOR_API_KEY}`,
    },
  });

  const simulationPayload = {
    contracted_demand_off_peak: params.contractedDemandOffPeak,
    contracted_demand_peak: params.contractedDemandPeak,
    energy_distribution_company: params.energyDistributionCompany,
    icms_type: `ICMS_${params.icmsType}`,
    measured_demand_off_peak: params.measuredDemandOffPeak,
    tariff: params.tariff,
    measured_demand_peak: params.measuredDemandPeak,
  };

  return (await calculatorApiClient.post('/api/v2/power-demand', simulationPayload).then((r) => {
    const data = r.data;
    return {
      ...data,
      data: {
        newAdjustedDemandOffPeak: data.data.new_adjusted_demand_off_peak,
        annualEconomyOffPeak: data.data.annual_economy_off_peak,
        newAdjustedDemandPeak: data.data.new_adjusted_demand_peak,
        annualEconomyPeak: data.data.annual_economy_peak,
        clarkeFee: data.data.clarke_fee,
        annualClarkeValue: data.data.annual_clarke_value,
        isWorthwhile: data.data.is_worthwhile,
      },
    };
  })) as ApiResponse<AdjustedDemandData>;
};
